import React, { useEffect, useState } from 'react';
import '../../../App.css';
import LayoutWithoutSideBar from '../../../components/partials/layout/DetailedPagesLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { apiCall } from '../../../fetch';

export default function SiteRestrictions() {
  const workOrderId = localStorage.getItem('workOrderId');
  const blackberryUserId = localStorage.getItem('bbUserId');
  const [siteRestriction, setSiteRestriction] = useState('');

  useEffect(async () => {
    const siteRestrictions = await apiCall({
      url: `https://api-dev.manninggroup.com/SiteRestriction/${workOrderId}`,
      method: 'GET',
    });
    let combineSiteRestrictions = siteRestriction;
    siteRestrictions.map((item) => {
      combineSiteRestrictions =
        combineSiteRestrictions + item.restrictiontext
          ? item.restrictiontext + ' '
          : '';
    });
    setSiteRestriction(combineSiteRestrictions);
  }, []);

  return (
    <LayoutWithoutSideBar
      heading="Site Restrictions"
      redirectTo="/project-binder"
      backArrow={true}
    >
      <div className="enduser-card">
        <Card className="siterestriction-card">
          <CardContent>
            <p className="site-restriction-textarea">
              {siteRestriction == ''
                ? 'Site restrictions are not added'
                : siteRestriction}
            </p>
          </CardContent>
        </Card>
      </div>
    </LayoutWithoutSideBar>
  );
}
