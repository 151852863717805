import React, { useEffect, useState } from 'react';
import '../../App.css';
import LayoutWithoutSideBar from '../../components/partials/layout/DetailedPagesLayout';
import { Card, Grid } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CardContent from '@mui/material/CardContent';
import AlertIcon from '../../images/icons/AlertReminder.jsx';
import { callApiWithToken } from '../../fetch';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../../authConfig';
import moment from 'moment';
import Loader from '../../components/loaders';
import { startDPRTimer } from '../../utils/timerUtils';

export default function Reminder() {
  const { instance, accounts } = useMsal();
  const workOrderId = Number(localStorage.getItem('workOrderId'));
  const blackberryuserId = Number(localStorage.getItem('bbUserId'));
  const schedulerId = Number(localStorage.getItem('schedulerId'));
  const mode = localStorage.getItem('mode');
  const reminderTypes = {
    1: 'Safety brief Reminder',
    2: 'Clock In/Out Reminder',
    3: 'DPR Reminder',
  };
  const [reminders, setReminders] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [load, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });

      const reminders = await callApiWithToken(response.accessToken, {
        url: `https://api-dev.manninggroup.com/ReminderNotification/${schedulerId}`,
        method: 'GET',
      });

      setLoader(false);
      setReminders(reminders);
      const reminderTypes = await callApiWithToken(response.accessToken, {
        url: `https://api-dev.manninggroup.com/ReminderNotification/type`,
        method: 'GET',
      });
      const reminderTypeDescriptions = {};
      reminderTypes.map((item) => {
        reminderTypeDescriptions[`${item.id}`] = item.description;
      });
      setDescriptions(reminderTypeDescriptions);
      setTimeout(() => {
        markAsUnread(reminders);
      }, 5000);
    })();
  }, []);

  useEffect(() => {
    const isRefreshed = localStorage.getItem('refreshed');
    if (isRefreshed) {
      let isDprReminderRunning = localStorage.getItem('dprReminder');
      isDprReminderRunning = JSON.parse(isDprReminderRunning);
      var diff = Math.abs(new Date() - new Date(isDprReminderRunning.time));
      let minutes = Math.floor(diff / 1000 / 60);
      localStorage.removeItem('refreshed');
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        const reminders = await callApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification/${isDprReminderRunning.schedulerId}`,
          method: 'GET',
        });
        if (reminders && reminders.length > 0) {
          let data = {
            id: 0,
            schedulerId: Number(reminders[0].schedulerId),
            blackberryuserId: Number(reminders[0].blackberryuserId),
            reminderNotificationTypeId: 3,
          };
          if (minutes > 5) {
            startDPRTimer(response.accessToken, data);
          } else {
            let timeRemaining = Math.floor((5 - minutes) * 60 * 1000);
            setTimeout(() => {
              startDPRTimer(response.accessToken, data);
            }, timeRemaining);
          }
        }
      })();
    }
  }, []);

  const markAsUnread = (reminders) => {
    instance
      .acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      })
      .then(async (response) => {
        const updatedReminders = [];
        for (let i in reminders) {
          let reminder = await callApiWithToken(response.accessToken, {
            url: `https://api-dev.manninggroup.com/ReminderNotification`,
            method: 'PUT',
            body: {
              id: reminders[i].id,
              schedulerId: reminders[i].schedulerId,
              blackberryuserId: reminders[i].blackberryuserId,
              reminderNotificationTypeId:
                reminders[i].reminderNotificationTypeId,
            },
          });
          updatedReminders.push(reminder);
        }
        setReminders(updatedReminders);
      });
  };

  return (
    <LayoutWithoutSideBar
      heading="Daily Reminders"
      redirectTo={mode == 'start' ? '/time-clock' : '/project-binder'}
      backArrow={true}
    >
      <Loader open={load} />
      <div className="enduser-card">
        <Card className="reminders-card">
          {reminders.length > 0 ? (
            <CardContent>
              {reminders.map((result, index) => (
                <Grid key={index}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={1}
                      className="alert-reminder-icon"
                      align="center"
                    >
                      <AlertIcon />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid className="alert-text">
                        {reminderTypes[result.reminderNotificationTypeId]}
                        {result.isRead == 0 && (
                          <CircleIcon
                            sx={{
                              color: '#43A047',
                              marginLeft: '10px',
                              width: '10px',
                            }}
                          />
                        )}
                      </Grid>
                      <Grid className="alert-subtext">
                        {descriptions[`${result.reminderNotificationTypeId}`]}
                      </Grid>
                      <Grid className="reminder-time">
                        {Math.round(
                          moment
                            .duration(
                              moment()
                                .local()
                                .diff(moment.utc(result.createdAt).local())
                            )
                            .asMinutes()
                        )}{' '}
                        min ago{' '}
                      </Grid>
                    </Grid>
                    <Grid item xs={3} align="right" className="new-reminders">
                      {result.isRead == 0 && <span>New</span>}
                    </Grid>
                  </Grid>
                  <hr className="hr-reminders" />
                </Grid>
              ))}
            </CardContent>
          ) : (
            <Grid sx={{ padding: '10px' }}>No reminders yet</Grid>
          )}
        </Card>
      </div>
    </LayoutWithoutSideBar>
  );
}
