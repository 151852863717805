import { createSelector } from 'reselect';

export const getApiAccessToken = createSelector(
  (state) => state.apiAccessToken,
  (token) => token
);

export const getUser = createSelector(
  (state) => state.user,
  (user) => user
);
