import React, { useEffect, useState } from 'react';
import { Grid, Paper, Box } from '@mui/material';
import '../../../App.css';
import LayoutWithoutSideBar from '../../../components/partials/layout/DetailedPagesLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MailIcon from '../../../images/icons/MailIcon';
import CallIcon from '../../../images/icons/CallIcon';
import { getUser } from '../../../redux/selectors/homePage';
import { connect } from 'react-redux';
import { loadUser } from '../../../redux/actions/userActions';
import { saveWorkOrder } from '../../../redux/actions/workOrderActions';
import { apiCall } from '../../../fetch';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export function EndUser(props) {
  const workOrderId = localStorage.getItem('workOrderId');
  const blackberryUserId = localStorage.getItem('bbUserId');
  const [workOrder, setWorkOrder] = useState({});

  useEffect(async () => {
    const userResponse = await apiCall({
      url: `https://api-dev.manninggroup.com/Foreman/${blackberryUserId}`,
      method: 'GET',
    });
    const workOrders = userResponse.workOrders ? userResponse.workOrders : [];
    workOrders.map((item) => {
      if (item.id == workOrderId) {
        setWorkOrder(item);
      }
    });
  }, []);

  return (
    <LayoutWithoutSideBar
      heading="End User Information"
      redirectTo="/project-binder"
      backArrow={true}
    >
      <div className="enduser-card">
        <Card>
          <CardContent>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <table className="mt10">
                  <tbody>
                    <tr>
                      <td className="enduser-headings">End User Name</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.endUserName ? workOrder.endUserName : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="enduser-headings">End User Address</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.endUserSiteAddress
                          ? workOrder.endUserSiteAddress
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={6}>
                <table className="mt10">
                  <tbody>
                    <tr>
                      <td className="enduser-headings">Work Order</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.id ? workOrder.id : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="enduser-headings">Project No</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.projectId ? workOrder.projectId : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="enduser-headings">Project Name</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.jobname ? workOrder.jobname : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="enduser-headings">Project Assigned By</td>
                      <td>:</td>
                      <td className="enduser-values">
                        {workOrder.projectManager &&
                        workOrder.projectManager.name
                          ? workOrder.projectManager.name
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <hr className="hr-enduser"></hr>
            <div className="enduser-headings margin20" align="left">
              Project Details :
              <p className="enduser-values">
                {workOrder.description ? workOrder.description : ''}
              </p>
            </div>

            {(workOrder.siteContact2 || workOrder.siteContactPhone1) && (
              <>
                <hr className="hr-enduser"></hr>
                <div align="left">
                  <p className="enduser-values">Contacts</p>
                </div>
                <div className="contacts-div">
                  {workOrder.siteContactPhone2 != workOrder.siteContactPhone1 &&
                    workOrder.siteContactPhone2 &&
                    workOrder.siteContactPhone1 && (
                      <Box className="d-flex enduser-twoPhonumbers">
                        <Grid className="call-icon" sx={{ padding: '7px' }}>
                          <CallIcon />
                        </Grid>
                        <Grid sx={{ padding: '10px', textAlign: 'left' }}>
                          <div
                            className="contact-span-text"
                            style={{ marginBottom: '5px', textAlign: 'left' }}
                          >
                            {workOrder.siteContactPhone1}
                          </div>
                          <div
                            className="contact-span-text"
                            style={{ textAlign: 'left' }}
                          >
                            {workOrder.siteContactPhone2}
                          </div>
                        </Grid>
                      </Box>
                    )}
                  {workOrder.siteContactPhone1 && !workOrder.siteContactPhone2 && (
                    <Item className="enduser-phone">
                      <CallIcon />
                      <span
                        style={{ marginLeft: '10px' }}
                        className="contact-span-text"
                      >
                        {workOrder.siteContactPhone1}
                      </span>
                    </Item>
                  )}
                  {workOrder.siteContact2 && (
                    <Item className="enduser-email">
                      <MailIcon />
                      <span
                        style={{ marginLeft: '10px' }}
                        className="contact-span-text"
                      >
                        {workOrder.siteContact2}
                      </span>
                    </Item>
                  )}
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </LayoutWithoutSideBar>
  );
}

function mapStateToProps(store) {
  const user = getUser(store);
  return {
    user,
    workOrders: user.workOrders ? user.workOrders : [],
  };
}

const mapDispatchToProps = {
  loadUser,
  saveWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(EndUser);
