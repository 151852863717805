import { ACTION_TYPES } from '../constants/action-types';

export const saveWorkOrder = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.SAVE_WORK_ORDER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
