import React from 'react';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Style from './styles-header.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import TemporaryDrawer from '../../drawers/SideDrawer';
import '../../../App.css';

export default function PageHeader({ redirectTo, backArrow, heading }) {
  const [slider, setSlider] = React.useState(false);
  const navigate = useNavigate();
  const goBack = (path) => {
    navigate(path);
  };

  return (
    <div style={{ backgroundColor: '1E417C' }}>
      <AppBar className="App-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 2 }}
            onClick={
              backArrow
                ? () => goBack(redirectTo ? redirectTo : '/')
                : () => setSlider(true)
            }
          >
            {backArrow ? <ArrowBackIcon /> : <MenuIcon />}
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <div className={Style.header_text}>{heading ? heading : ''}</div>
          </Box>
        </Toolbar>
      </AppBar>
      {slider && <TemporaryDrawer handleClose={setSlider} />}
    </div>
  );
}

PageHeader.prototype = {
  redirectTo: PropTypes.string.isRequired,
  backArrow: PropTypes.bool,
  heading: PropTypes.string.isRequired,
};
