import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../../../components/loaders';
import { apiCall } from '../../../fetch';
import moment from 'moment';
import { useStateIfMounted } from 'use-state-if-mounted';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function CheckList() {
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const [checklist, setChecklist] = useState([]);
  const [open, setOpen] = useState(true);
  const workOrderId = Number(localStorage.getItem('workOrderId'));
  const blackberryUserId = Number(localStorage.getItem('bbUserId'));
  const schedulerId = Number(localStorage.getItem('schedulerId'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    apiCall({
      url: `https://api-dev.manninggroup.com/Checklist/${schedulerId}`,
      method: 'GET',
    }).then((checklistResponse) => {
      setChecklist(checklistResponse);
      setOpen(false);
    });
    return () => clearInterval(timer);
  }, []);

  const handleGoalChange = (item) => async (event) => {
    let updatedChecklist = [...checklist];
    try {
      if (event.target.checked) {
        const response = await apiCall({
          url: `https://api-dev.manninggroup.com/Checklist/${item.checklistActions[0].id}/Checked`,
          method: 'POST',
        });
        updatedChecklist = updatedChecklist.map((element) => {
          if (item.id == element.id) {
            element.checklistActions[0] = response;
          }
          return element;
        });
      } else {
        const response = await apiCall({
          url: `https://api-dev.manninggroup.com/Checklist/${item.checklistActions[0].id}/UnChecked`,
          method: 'POST',
        });
        updatedChecklist = updatedChecklist.map((element) => {
          if (item.id == element.id) {
            element.checklistActions[0] = response;
          }
          return element;
        });
      }
      setChecklist(updatedChecklist);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid item xs={9}>
      <Loader open={open} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <div className="project-goals-heading">Safety Brief Checklist</div>
        </Grid>
        <Grid item xs={6}>
          <div className="current-time">
            Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
          </div>
        </Grid>
      </Grid>
      <div className="card-checkbox mt20">
        <FormGroup>
          {checklist.length ? (
            <FormGroup>
              {checklist.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      {...label}
                      color="success"
                      checked={
                        item.checklistActions[0].checked === 0 ? false : true
                      }
                      onChange={handleGoalChange(item)}
                    />
                  }
                  label={item.displaytext}
                />
              ))}
            </FormGroup>
          ) : (
            <div className="margin10">Not Available</div>
          )}
        </FormGroup>
      </div>
    </Grid>
  );
}
