import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../../authConfig';
import LayoutWithoutSideBar from '../../components/partials/layout/DetailedPagesLayout';
import { getUser } from '../../redux/selectors/homePage';
import { connect } from 'react-redux';
import { storeAccessToken, loadUser } from '../../redux/actions/userActions';
import { saveWorkOrder } from '../../redux/actions/workOrderActions';

import { deleteTimeCard } from '../../redux/actions/timeCardActions';
import WorkOrderAccordian from '../../components/accordians/WorkOrderAccordian';
import Loader from '../../components/loaders';
import { callApiWithToken } from '../../fetch';
import { startDPRTimer } from '../../utils/timerUtils';

export function Login(props) {
  const { instance, accounts } = useMsal();
  const { loadUser, schedules, user, deleteTimeCard } = props;
  const [open, setOpen] = React.useState(true);
  const accountName = localStorage.getItem('accountName');
  const reOpened = localStorage.getItem('reOpened');
  localStorage.clear();
  localStorage.setItem('accountName', accountName);
  localStorage.setItem('reOpened', reOpened);

  useEffect(async () => {
    deleteTimeCard();
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    localStorage.setItem('accountName', accounts[0].name);
    await loadUser(response.accessToken, accounts[0].username);
    setOpen(false);
  }, []);

  const startSchedule = async (scheduleId, bbUserId) => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    callApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/Foreman/Login`,
      method: 'POST',
      body: {
        foremanId: bbUserId,
        schedulerId: scheduleId,
      },
    }).catch(() => console.log(error));
  };

  useEffect(() => {
    const isRefreshed = localStorage.getItem('refreshed');
    if (isRefreshed) {
      let isDprReminderRunning = localStorage.getItem('dprReminder');
      isDprReminderRunning = JSON.parse(isDprReminderRunning);
      var diff = Math.abs(new Date() - new Date(isDprReminderRunning.time));
      let minutes = Math.floor(diff / 1000 / 60);
      localStorage.removeItem('refreshed');
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        const reminders = await callApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification/${isDprReminderRunning.schedulerId}`,
          method: 'GET',
        });
        if (reminders && reminders.length > 0) {
          let data = {
            id: 0,
            schedulerId: Number(reminders[0].schedulerId),
            blackberryuserId: Number(reminders[0].blackberryuserId),
            reminderNotificationTypeId: 3,
          };
          if (minutes > 5) {
            startDPRTimer(response.accessToken, data);
          } else {
            let timeRemaining = Math.floor((5 - minutes) * 60 * 1000);
            setTimeout(() => {
              startDPRTimer(response.accessToken, data);
            }, timeRemaining);
          }
        }
      })();
    }
  }, []);

  return (
    <div>
      <Loader open={open} />
      <LayoutWithoutSideBar heading="My Work Orders">
        <div className="welcome-heading">
          <span className="welcome-text">Welcome</span>
          <span className="welcome-ffa"> To Field Force Automation</span>
        </div>
        <Divider style={{ backgroundColor: '#F58C1A' }} />
        {schedules.length ? (
          schedules.map((schedule, index) => (
            <div className="margin20" key={index}>
              <WorkOrderAccordian
                schedule={schedule}
                bbUserId={user.blackberryUserId}
                startSchedule={() =>
                  startSchedule(schedule.id, user.blackberryUserId)
                }
              />
            </div>
          ))
        ) : (
          <div className="margin40">No upcoming scheduled work</div>
        )}
      </LayoutWithoutSideBar>
    </div>
  );
}

function mapStateToProps(store) {
  const user = getUser(store);
  let schedules = user.schedules ? user.schedules : [];
  let workOrders = user.workOrders ? user.workOrders : [];
  schedules = schedules.map((schedule) => {
    workOrders.forEach((workOrder) => {
      if (workOrder.id == schedule.workorderId) {
        schedule.workOrder = workOrder;
      }
    });
    return schedule;
  });
  return {
    user,
    schedules,
  };
}

const mapDispatchToProps = {
  storeAccessToken,
  loadUser,
  saveWorkOrder,
  deleteTimeCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
