import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { loadTimeCard } from '../../redux/actions/timeCardActions';
import moment from 'moment';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '1px solid #45454533',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  marginBottom: '10px',
  borderRadius: '4px',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)' }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  background: 'linear-gradient(to right,#E5E5E5 0.5%,#FFFFFF 0.5%) no-repeat',
  borderRadius: '4px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: '10px',
  borderRadius: '4px',
  backgroundColor: '#ECF1F4',
}));

export function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState(0);
  const { schedule, bbUserId } = props;
  const siteAddressObject = schedule.workOrder?.siteAddress;
  const siteAddress =
    siteAddressObject.address1 +
    ' ' +
    siteAddressObject.adressOther +
    ',' +
    siteAddressObject.city +
    ' ' +
    siteAddressObject.stateName +
    ' ' +
    siteAddressObject.postalcode;
  const navigate = useNavigate();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const planWorkOrder = () => {
    localStorage.removeItem('isCompleted');
    localStorage.setItem('mode', 'plan');
    localStorage.setItem('bbUserId', bbUserId);
    localStorage.setItem('schedulerId', schedule.id);
    localStorage.setItem('workOrderId', schedule.workorderId);
    localStorage.setItem('scheduleStartDate', new Date(schedule.startTime));
    navigate('/project-binder');
  };

  const startWorkOrder = async () => {
    localStorage.setItem('mode', 'start');
    localStorage.setItem('schedulerId', schedule.id);
    localStorage.setItem('bbUserId', bbUserId);
    localStorage.setItem('workOrderId', schedule.workorderId);
    props.loadTimeCard(schedule.id);
    if (schedule.scheduleStatus == 'Completed') {
      localStorage.setItem('isCompleted', true);
      navigate('/project-binder');
    } else {
      props.startSchedule(schedule.id, bbUserId);
      localStorage.removeItem('isCompleted');
      navigate('/time-clock');
    }
  };

  let statusColor = '';
  if (schedule.scheduleStatus == 'Not Started')
    statusColor = 'schedule-status-paper-yellow';
  if (schedule.scheduleStatus == 'Completed')
    statusColor = 'schedule-status-paper-green';
  if (schedule.scheduleStatus == 'In Progress')
    statusColor = 'schedule-status-paper-purple';

  return (
    <div>
      <Accordion
        expanded={expanded === schedule.id}
        onChange={handleChange(schedule.id)}
        className={expanded ? 'accordian-active' : ''}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          className={!expanded ? `` : 'side-accordian-active'}
        >
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 2, sm: 2, md: 3 }}
              sx={{ alignItems: 'center' }}
            >
              <Grid item xs={3}>
                <div className="workorder-list-headings">
                  Project Name:{' '}
                  <span className="workorder-list-values">
                    {schedule.workOrder?.jobname}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="workorder-list-headings">
                  Work Order:{' '}
                  <span className="workorder-list-values">
                    {schedule.workorderId ? schedule.workorderId : ''}
                  </span>
                </div>
              </Grid>
              <Grid item xs={3.5}>
                <div className="workorder-list-headings alignCenter">
                  Schedule start date & time:{' '}
                  {schedule.startTime ? (
                    <span className="workorder-list-values">
                      {moment(schedule.startTime).format('DD-MM-YYYY HH:mm')}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
              <Grid item xs={1.5}>
                <Paper className={`schedule-status-paper ${statusColor}`}>
                  <span className="schedule-status">
                    {schedule.scheduleStatus}
                  </span>
                </Paper>
              </Grid>
              <MediaQuery maxDeviceWidth={865}>
                <Grid item xs={2} align="center">
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={planWorkOrder}
                      className="plan-button"
                    >
                      plan
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={!schedule.isSafetyBriefDownloaded}
                      variant="outlined"
                      size="small"
                      className={
                        schedule.isSafetyBriefDownloaded == false
                          ? ''
                          : 'start-workorder-button'
                      }
                      onClick={startWorkOrder}
                    >
                      start
                    </Button>
                  </Grid>
                </Grid>
              </MediaQuery>
              <MediaQuery minDeviceWidth={866}>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={planWorkOrder}
                    className="plan-button"
                  >
                    plan
                  </Button>
                </Grid>
                <Grid item className="cutom-start-button" xs={1}>
                  <Button
                    disabled={!schedule.isSafetyBriefDownloaded}
                    variant="outlined"
                    size="small"
                    className={
                      schedule.isSafetyBriefDownloaded == false
                        ? ''
                        : 'start-workorder-button'
                    }
                    onClick={startWorkOrder}
                  >
                    start
                  </Button>
                </Grid>
              </MediaQuery>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4}>
                <div className="workorder-list-headings">
                  Site Address:{'  '}
                  <span className="workorder-list-values">
                    {siteAddress ? siteAddress : ''}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="workorder-list-headings">
                  Project Manager:{'  '}
                  <span className="workorder-list-values">
                    {schedule.workOrder.projectManager?.name}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="workorder-list-headings">
                  End User:{'  '}
                  <span className="workorder-list-values">
                    {schedule.workOrder?.endUserName}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <div className="workorder-list-headings">
                  Schedule End date & time:{'  '}
                  <span className="workorder-list-values">
                    {moment(schedule.endTime).format('DD-MM-YYYY HH:mm')}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="workorder-list-headings">
                  End User Address:{'  '}
                  <span className="workorder-list-values">
                    {schedule.workOrder?.endUserSiteAddress}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function mapStateToProps(store) {
  return {};
}

const mapDispatchToProps = {
  loadTimeCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizedAccordions);
