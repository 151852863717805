import React, { useState, useEffect } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { callApiWithToken, callPdfApiWithToken } from '../../fetch';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Layout from '../../components/partials/layout/Layout';
import { protectedResources } from '../../authConfig';
import PdfIcon from '../../images/icons/PdfIcon';
import PdfDownload from '../../images/icons/PdfDownload';
import { useStateIfMounted } from 'use-state-if-mounted';

export function MSDS() {
  const [msdsDocuments, setMsdsDocuments] = useState([]);
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    (async () => {
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });
      callApiWithToken(response.accessToken, {
        url: 'https://api-dev.manninggroup.com/ProjectBinder/MSDS',
        method: 'GET',
      })
        .then((result) => {
          if (result) {
            setMsdsDocuments(result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, []);

  const PdfBox = (props) => {
    return (
      <Box className="scope-pdfs">
        <Grid item xs={1.5} align="center">
          <PdfIcon />
        </Grid>
        <Grid item xs={7.5} className="pdf-name">
          {props.result.fileName}
        </Grid>
        <Grid item xs={3} align="right">
          <IconButton onClick={() => downloadPdf(props.result)}>
            <PdfDownload />
          </IconButton>
        </Grid>
      </Box>
    );
  };

  const downloadPdf = async (fileData) => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const base64Pdf = await callPdfApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/ProjectBinder/${fileData.fileId}`,
      method: 'GET',
    });
    var a = document.createElement('a');
    a.setAttribute('download', fileData.fileName);
    a.setAttribute('href', `data:${fileData.mimeType};base64,${base64Pdf}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Layout heading="MSDS Documents">
      <Grid container>
        <Grid item xs={4}>
          <div className="msds-heading">MSDS</div>
        </Grid>
        <Grid item xs={6}>
          <div className="current-time">
            Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ margin: '25px' }}>
        <Grid>
          {msdsDocuments.map((result, index) => (
            <PdfBox result={result} key={index} />
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
}
