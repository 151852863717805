import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      fill="none"
      viewBox="0 0 19 23"
    >
      <path
        fill="#1E417C"
        fillRule="evenodd"
        d="M17.543 13.974l1.282 2.402a1.44 1.44 0 01-.04 1.446 1.444 1.444 0 01-1.263.723H1.478c-.52 0-.998-.275-1.262-.723a1.459 1.459 0 01-.041-1.456l1.283-2.402V8.039c0-3.573 2.341-6.607 5.568-7.656a8.651 8.651 0 013.431-.336c.02.01.05.01.071.01.387.041.784.123 1.201.245v.01c3.35.977 5.814 4.072 5.814 7.737v5.925zM9.51 1.177A6.876 6.876 0 002.638 8.05v5.436h13.734V8.05c0-3.797-3.075-6.872-6.862-6.872zm8.012 16.187a.287.287 0 00.257-.147c.022-.043.077-.153.008-.29l-1.201-2.26H2.425l-1.212 2.26c-.071.142-.02.254.01.294l.003.004c.032.043.104.139.252.139h16.044zM9.51 21.497a2.604 2.604 0 002.566-2.19h1.242c-.224 1.915-1.833 3.411-3.798 3.411-1.975 0-3.583-1.496-3.797-3.41h1.222a2.605 2.605 0 002.565 2.189z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
