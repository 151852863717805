import { callApiWithToken, apiCall } from '../../fetch';
import { ACTION_TYPES as types } from '../constants/action-types';

export function loadUser(accessToken, emailId) {
  return async (dispatch) => {
    try {
      const userInfo = await callApiWithToken(accessToken, {
        url: 'https://api-dev.manninggroup.com/user/mobile?overrideemail=travis@manninggroup.com',
        method: 'GET',
      });
      let blackberryuser = {};
      if (userInfo && userInfo.blackberryuser) {
        const bbUserResponse = await apiCall({
          url: `https://api-dev.manninggroup.com/foreman/${userInfo.blackberryuser.id}`,
          method: 'GET',
        });
        blackberryuser = bbUserResponse;
      }
      dispatch({
        type: types.LOAD_USER_SUCCESS,
        payload: blackberryuser,
      });
    } catch (error) {
      throw error;
    }
  };
}

export async function storeAccessToken(apiAccessToken) {
  return async (dispatch) => {
    return dispatch({
      type: types.STORE_API_ACCESS_TOKEN_SUCCESS,
      payload: apiAccessToken,
    });
  };
}
