import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FooterImg from '../../images/sidemenu/footer-small.svg';
import WorkOrdersIcon from '../../images/icons/WorkOrdersIcon';
import RemainderIcon from '../../images/icons/RemaindersIcon';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

export default function TemporaryDrawer(props) {
  const [open, setOpen] = React.useState(true);
  const [accountName] = React.useState(
    localStorage.getItem('accountName')
      ? localStorage.getItem('accountName')
      : ''
  );
  const path = useLocation();
  const pathName = path.pathname.split('/')[1];
  const { instance } = useMsal();
  const navigate = useNavigate();

  const toggleDrawer = (state) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(state);
    props.handleClose(false);
  };

  const handleLogout = (logoutType) => {
    localStorage.clear();
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <Grid className="sidebar-nametag">
        <div className="sidebar-name-text">Travis Arnell</div>
      </Grid>
      <List>
        <ListItem button onClick={() => navigate('/')}>
          <ListItemIcon>
            <WorkOrdersIcon />
          </ListItemIcon>
          <span className="sidebarList">My Work Orders</span>
        </ListItem>
        {pathName != '' && (
          <ListItem button onClick={() => navigate('/reminders')}>
            <ListItemIcon>
              <RemainderIcon />
            </ListItemIcon>
            <span className="sidebarList">Daily Reminders</span>
          </ListItem>
        )}
      </List>
      <Button
        className="logout-button"
        onClick={() => handleLogout('redirect')}
      >
        Logout
      </Button>
      <Grid>
        <img src={FooterImg} alt="footer-img" className="sidebar-footer" />
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
