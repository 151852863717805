import React, { useState, useEffect } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import '../../../App.css';
import { protectedResources } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { callApiWithToken, callPdfApiWithToken } from '../../../fetch';
import moment from 'moment';
import PdfIcon from '../../../images/icons/PdfIcon';
import PdfDownload from '../../../images/icons/PdfDownload';
import Loader from '../../../components/loaders/index';
import { useStateIfMounted } from 'use-state-if-mounted';
import ActionAlerts from '../../../components/alerts/Toasts';

export default function SafetyPdf() {
  const { instance, accounts } = useMsal();
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const [pdf, setPdf] = useState({});
  const [loader, setLoader] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertText, setAlertText] = useState('Downloaded successfully');
  const schedulerId = localStorage.getItem('schedulerId');
  const bbUserId = localStorage.getItem('bbUserId');

  useEffect(async () => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const file = await callApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/SafetyBriefs/${bbUserId}/${schedulerId}`,
      method: 'GET',
    });
    if (file != null) {
      setPdf(file);
    }
    setLoader(false);
    return () => clearInterval(timer);
  }, []);

  const PdfBox = (props) => {
    return (
      <Box className="scope-pdfs">
        <Grid item xs={1.5} align="center">
          <PdfIcon />
        </Grid>
        <Grid item xs={7.5} className="pdf-name">
          {props.result.fileName}
        </Grid>
        <Grid item xs={3} align="right">
          <IconButton onClick={() => downloadPdf(props.result)}>
            <PdfDownload />
          </IconButton>
        </Grid>
        {/* <Grid item xs={3} align="center">
          <IconButton onClick={() => viewPdf(props.result)}>
            <PdfView />
          </IconButton>
        </Grid> */}
      </Box>
    );
  };

  const downloadPdf = async (fileData) => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const base64Pdf = await callPdfApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/SafetyBriefs/download/${bbUserId}/${schedulerId}/${fileData.fileId}`,
      method: 'GET',
    });
    setAlert(true);
    var a = document.createElement('a');
    a.setAttribute('download', fileData.fileName);
    a.setAttribute('href', `data:${fileData.mimeType};base64,${base64Pdf}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };
  return (
    <Grid item xs={9}>
      <ActionAlerts
        open={alert}
        type={alertType}
        description={alertText}
        handleClose={() => setAlert(false)}
      />
      <Loader open={loader} />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: '30px' }}
      >
        <Grid item xs={3.5}>
          <div className="project-goals-heading">Safety Pdf</div>
        </Grid>
        <Grid item xs={5.5}>
          <div className="current-time">
            Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
          </div>
        </Grid>
      </Grid>
      <br />

      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Grid>
          {Object.keys(pdf).length > 0 ? <PdfBox result={pdf} /> : <></>}
        </Grid>
      </Grid>
    </Grid>
  );
}
