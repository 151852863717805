import { combineReducers } from 'redux';
import { user, apiAccessToken } from './userReducer';
import { saveWorkOrder } from './workOrderReducer';
import { timeCardSave } from './timeCardReducer';

const reducers = combineReducers({
  user,
  apiAccessToken,
  workOrder: saveWorkOrder,
  timeCards: timeCardSave,
});

export default reducers;
