import React from 'react';
import { AppBar, LinearProgress } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Style from './styles-header.module.css';
import TemporaryDrawer from '../../drawers/SideDrawer';
import '../../../App.css';
import Notifition from '../../../images/icons/NotificationIcon';
import { styled } from '@mui/system';
import BadgeUnstyled from '@mui/base/BadgeUnstyled';

export default function Header(props) {
  const [slider, setSlider] = React.useState(false);
  const navigate = useNavigate();

  const StyledBadge = styled(BadgeUnstyled)`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    position: relative;
    display: inline-block;
    line-height: 1;

    & .MuiBadge-badge {
      z-index: auto;
      min-width: 20px;
      height: 20px;
      padding: 0 6px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      text-align: center;
      background: #15b891;
      border: 2px solid #1e417c;
    }

    & .MuiBadge-dot {
      padding: 0;
      z-index: auto;
      min-width: 6px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #15b891;
      border: 2px solid #1e417c;
    }

    & .MuiBadge-anchorOriginTopRight {
      position: absolute;
      top: 2px;
      right: 4px;
      transform: translate(50%, -50%);
      transform-origin: 100% 0;
    }
  `;

  return (
    <div style={{ backgroundColor: '1E417C' }}>
      <AppBar className="App-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setSlider(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <div className={Style.header_text}>{props.heading}</div>
          </Box>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={() => navigate('/reminders')}
          >
            {props.notification == true ? (
              <StyledBadge variant="dot">
                <Notifition />
              </StyledBadge>
            ) : (
              <Notifition />
            )}
          </IconButton>
        </Toolbar>
        {props.scan && <LinearProgress />}
      </AppBar>
      {slider && <TemporaryDrawer handleClose={setSlider} />}
    </div>
  );
}
