import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectBinderPage from '../screens/project-binder/index.jsx';
import HomePage from '../screens/home-page/index';
import EndUser from '../screens/project-binder/project-scope/EndUser.jsx';
import SiteRestrictions from '../screens/project-binder/project-scope/SiteRestriction.jsx';
import TimeClock from '../screens/time-clock/index.jsx';
import SafetyBriefs from '../screens/briefs/index.jsx';
import Reminders from '../screens/reminders/index.jsx';
import { MSDS } from '../screens/msds/index.jsx';

class AppRouter extends Component {
  render() {
    return (
      <Routes>
        <Route path="/project-binder" element={<ProjectBinderPage />} />
        <Route exact path="/" element={<HomePage />} />
        <Route path="/end-user" element={<EndUser />} />
        <Route path="/site-restriction" element={<SiteRestrictions />} />
        <Route path="/time-clock" element={<TimeClock />} />
        <Route path="/briefs" element={<SafetyBriefs />} />
        <Route path="/reminders" element={<Reminders />} />
        <Route path="/msds" element={<MSDS />} />
      </Routes>
    );
  }
}

export default AppRouter;
