import React from 'react';
import PageHeader from '../header/PageHeader';
import Footer from '../footer/Footer.jsx';

export default function LayoutWithoutSideBar(props) {
    return (
        <React.Fragment>
            <PageHeader 
                heading={props.heading ? props.heading : ""}
                redirectTo={props.redirectTo ? props.redirectTo : ""}
                backArrow={props.backArrow ? props.backArrow : false}
            />
                {props.children}
            <Footer />
        </React.Fragment>
    )
}