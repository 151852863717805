import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Dialog, Grid, Box, Typography } from '@mui/material';
import AlertIcon from '../../images/icons/AlertIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

export default function ActionAlerts(props) {
  const { type, description, open, handleClose } = props;
  return (
    <>
      {type === 'alert' && (
        <Dialog
          open={open}
          className="alert-dialog"
          onClose={handleClose}
          onBackdropClick={handleClose}
        >
          <Box>
            <Alert
              sx={{
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                alignItems: 'center',
                background:
                  'linear-gradient(to right, #FDD835 2%, #ffffff 0.5%) no-repeat !important',
              }}
              className="alert-toast"
              icon={<AlertIcon />}
              onClose={handleClose}
            >
              <Typography className="alert-text">{type}</Typography>
              <AlertTitle className="alert-description">
                {description}
              </AlertTitle>
            </Alert>
          </Box>
        </Dialog>
      )}
      {type === 'success' && (
        <Dialog
          open={open}
          className="alert-dialog"
          onClose={handleClose}
          onBackdropClick={handleClose}
        >
          <Box>
            <Alert
              sx={{
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                alignItems: 'center',
                background:
                  'linear-gradient(to right, #43A047 2%, #ffffff 0.5%) no-repeat !important',
              }}
              className="alert-toast"
              icon={<CheckBoxIcon />}
              onClose={handleClose}
            >
              <Typography className="alert-text">{type}</Typography>
              <AlertTitle className="alert-description">
                {description}
              </AlertTitle>
            </Alert>
          </Box>
        </Dialog>
      )}
      {type === 'error' && (
        <Dialog
          open={open}
          className="alert-dialog"
          onClose={handleClose}
          onBackdropClick={handleClose}
        >
          <Box>
            <Alert
              sx={{
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                alignItems: 'center',
                background:
                  'linear-gradient(to right, #E65100 2%, #ffffff 0.5%) no-repeat !important',
              }}
              className="alert-toast toast-error"
              icon={<DoDisturbAltIcon />}
              onClose={handleClose}
            >
              <Typography className="alert-text">{type}</Typography>
              <AlertTitle className="alert-description">
                {description}
              </AlertTitle>
            </Alert>
          </Box>
        </Dialog>
      )}
    </>
  );
}
