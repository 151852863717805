import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="32"
      fill="none"
      viewBox="0 0 22 32"
    >
      <path
        fill="#78797A"
        fillRule="evenodd"
        d="M2.667 0h16.677A2.653 2.653 0 0122 2.667v22.581a2.67 2.67 0 01-2.667 2.667H17.62a7.794 7.794 0 01-6.66 3.617 7.825 7.825 0 01-6.523-3.617h-1.77A2.67 2.67 0 010 25.25V2.666A2.669 2.669 0 012.667 0zm1.04 26.455a7.753 7.753 0 01-.488-2.796c.03-4.311 3.562-7.785 7.873-7.742 4.311.03 7.785 3.563 7.742 7.874a7.77 7.77 0 01-.488 2.664h.998c.677 0 1.217-.55 1.217-1.217V2.656c0-.677-.55-1.217-1.217-1.217H2.667c-.678 0-1.217.55-1.217 1.217v22.582c0 .677.55 1.217 1.217 1.217h1.04zM15.652 6.349H7.09a.743.743 0 00-.74.74c0 .408.332.742.74.742h8.563c.407 0 .74-.334.74-.741a.743.743 0 00-.74-.74zM9.207 9.704h3.272c.407 0 .74.333.74.74 0 .408-.333.741-.74.741H9.206a.743.743 0 01-.74-.74c0-.408.333-.741.74-.741zm1.872 7.713a6.301 6.301 0 00-6.36 6.253v.003c-.035 3.479 2.77 6.324 6.256 6.36a6.3 6.3 0 006.36-6.254v-.003a6.3 6.3 0 00-6.253-6.36h-.003zm-.44 1.535a.75.75 0 01.744.757l-.034 3.92 2.397 2.14a.75.75 0 01-.998 1.12l-2.651-2.366-.254-.227.003-.34.037-4.26a.75.75 0 01.756-.744z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
