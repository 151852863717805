import { ACTION_TYPES } from '../constants/action-types';

export const user = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_USER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const apiAccessToken = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.STORE_API_ACCESS_TOKEN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
