import * as React from 'react';
import Modal from '@mui/material/Modal';

export default function BasicModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {props.body}
      </Modal>
    </div>
  );
}
