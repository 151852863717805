import { Backdrop, CircularProgress } from '@mui/material';
export default function Loader(props) {
  return (
    <Backdrop
      sx={{ color: '#1e417c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
