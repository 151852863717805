import { callApiWithToken, apiCall } from '../../fetch';
import { ACTION_TYPES as types } from '../constants/action-types';

export function loadTimeCard(schedulerId) {
  return async (dispatch) => {
    try {
      const response = await apiCall({
        url: `https://api-dev.manninggroup.com/Timecards/${schedulerId}`,
        method: 'GET',
      });
      dispatch({
        type: types.LOAD_TIMECARD_ENTRIES_SUCCESS,
        payload: response.timecards ? response.timecards : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function deleteTimeCard() {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_TIMECARD_ENTRIES_SUCCESS,
      payload: [],
    });
  };
}
