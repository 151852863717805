import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="31"
      fill="none"
      viewBox="0 0 21 31"
    >
      <path
        fill="#5B5B5B"
        fillRule="evenodd"
        d="M9.676 2.103h8.36a2.877 2.877 0 012.863 2.842V27.61a2.845 2.845 0 01-2.843 2.842H2.853A2.847 2.847 0 010 27.61V4.945a2.854 2.854 0 012.853-2.842h1.661l.094-.178A2.84 2.84 0 017.095.452c1.024 0 1.985.564 2.487 1.474l.094.177zM7.085 1.78c-.032 0-.47.01-.836.324h1.713c-.177-.167-.449-.324-.877-.324zM2.832 29.021h15.203c.784 0 1.411-.627 1.39-1.411V4.945c0-.783-.637-1.41-1.41-1.41H9.916v7.952a2.845 2.845 0 01-2.842 2.842 2.845 2.845 0 01-2.842-2.842V6.722h1.442v4.765c0 .773.637 1.41 1.41 1.41.784 0 1.411-.637 1.411-1.41V3.535H2.832c-.774 0-1.41.637-1.41 1.41V27.61c0 .784.636 1.41 1.41 1.41zm1.41-4.42h8.088v1.442H4.242V24.6zm12.341-3.616H4.243v1.442h12.34v-1.442zm0-3.824H4.243v1.442h12.34V17.16zm0-3.877h-5.768v1.442h5.768v-1.442z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
