import React from 'react';
import FooterImg from '../../../images/png/ffa-footer-image.png';

export default function Footer() {
  return (
    <div className="footer-layout">
      <img src={FooterImg} alt="footer-img" />
    </div>
  );
}
