import { createStore, applyMiddleware } from 'redux';
import rootReducer from './index';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// For dev

export default function configureStore(initialState) {
  // if (process.env.NODE_ENV == 'production') {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
  // } else {
  //   return createStore(
  //     rootReducer,
  //     initialState,
  //     composeWithDevTools(applyMiddleware(thunk))
  //   );
  // }
}
