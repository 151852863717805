import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, IconButton } from '@mui/material';
import '../../../App.css';
import { protectedResources } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { apiCall, callApiWithToken, callPdfApiWithToken } from '../../../fetch';
import moment from 'moment';
import PdfIcon from '../../../images/icons/PdfIcon';
import PdfDownload from '../../../images/icons/PdfDownload';
import PdfView from '../../../images/icons/PdfView';
import Loader from '../../../components/loaders/index';
import { useStateIfMounted } from 'use-state-if-mounted';
import ActionAlerts from '../../../components/alerts/Toasts';

export default function Scope() {
  const { instance, accounts } = useMsal();
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertText, setAlertText] = useState('Downloaded successfully');
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const [documents, setDocuments] = useState({
    workOrders: [],
    deliveryTickets: [],
    prints: [],
    msds: [],
  });
  const [loader, setLoader] = useState(true);
  const workOrderId = localStorage.getItem('workOrderId');
  const blackberryUserId = localStorage.getItem('bbUserId');
  const schedulerId = localStorage.getItem('schedulerId');
  const [address, setAddress] = useState('');
  const navigate = useNavigate();

  useEffect(async () => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const files = await callApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/ProjectBinder/${workOrderId}`,
      method: 'GET',
    });
    if (files != null && files.length > 0) {
      const filesArray = {
        workOrders: [],
        deliveryTickets: [],
        prints: [],
        msds: [],
      };
      files.map((element) => {
        if (element.digitalbinderDocumenttypeId == 1) {
          filesArray.workOrders.push({
            ...element,
          });
        }
        if (element.digitalbinderDocumenttypeId == 2) {
          filesArray.deliveryTickets.push({
            ...element,
          });
        }
        if (element.digitalbinderDocumenttypeId == 3) {
          filesArray.prints.push({
            ...element,
          });
        }
        if (element.digitalbinderDocumenttypeId == 4) {
          filesArray.msds.push({
            ...element,
          });
        }
      });
      setDocuments(filesArray);
    }
    setLoader(false);
    const userResponse = await apiCall({
      url: `https://api-dev.manninggroup.com/Foreman/${blackberryUserId}`,
      method: 'GET',
    });
    const workOrders = userResponse.workOrders ? userResponse.workOrders : [];
    let selectedWorkOrder = {};
    workOrders.map((item) => {
      if (item.id == workOrderId) {
        selectedWorkOrder = item;
      }
    });
    let address = selectedWorkOrder.siteAddress
      ? selectedWorkOrder.siteAddress?.address1 +
        ' ' +
        selectedWorkOrder.siteAddress?.city
      : '';
    setAddress(address);
    return () => clearInterval(timer);
  }, []);

  const PdfBox = (props) => {
    return (
      <Box className="scope-pdfs">
        <Grid item xs={1.5} align="center">
          <PdfIcon />
        </Grid>
        <Grid item xs={7.5} className="pdf-name">
          {props.result.fileName}
        </Grid>
        <Grid item xs={3} align="right">
          <IconButton onClick={() => downloadPdf(props.result)}>
            <PdfDownload />
          </IconButton>
        </Grid>
        {/* <Grid item xs={3} align="center">
          <IconButton onClick={() => viewPdf(props.result)}>
            <PdfView />
          </IconButton>
        </Grid> */}
      </Box>
    );
  };
  const handleClick = (toPage) => {
    navigate(toPage);
  };

  const viewPdf = async (fileData) => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const base64Pdf = await callPdfApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/ProjectBinder/${fileData.fileId}`,
      method: 'GET',
    });
    // var iframe =
    //   "<iframe width='100%' height='100%' src='" +
    //   `data:${fileData.mimeType};base64,${base64Pdf}` +
    //   "'></iframe>";
    // var x = window.open();
    // x.document.open();
    // x.document.write(iframe);
    // x.document.close();
    let link = document.createElement('a');
    let dataurl = `data:${fileData.mimeType};base64,${base64Pdf}`;
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], { type: mime });
    // link.setAttribute('target', '_blank');
    // link.href = URL.createObjectURL(blob);
    // link.click();
    // URL.revokeObjectURL(link.href);
    window.open(URL.createObjectURL(blob));
  };

  const downloadPdf = async (fileData) => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    setAlert(true);
    const base64Pdf = await callPdfApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/ProjectBinder/${fileData.fileId}`,
      method: 'GET',
    });
    var a = document.createElement('a');
    a.setAttribute('download', fileData.fileName);
    a.setAttribute('href', `data:${fileData.mimeType};base64,${base64Pdf}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  return (
    <Grid item xs={10}>
      <ActionAlerts
        open={alert}
        type={alertType}
        description={alertText}
        handleClose={() => setAlert(false)}
      />
      <Loader open={loader} />
      <Grid item xs={10}>
        <div className="current-time">
          Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
        </div>
      </Grid>
      <br />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Button
            sx={{
              color: '#000000',
              background: '#CBD3FF',
              border: 0,
              height: '50px',
              borderRadius: '8px',
              width: '-webkit-fill-available',
            }}
            variant="outlined"
            className="scope-buttons"
            onClick={(e) => handleClick('/end-user')}
          >
            End User Information
          </Button>
          {/* <Link to="/end-user">End User Information</Link> */}
        </Grid>
        <Grid item xs={4}>
          <a href={`https://maps.google.com/?q=${address}`} target="_blank">
            <Button
              sx={{
                color: '#000000',
                background: '#CBD3FF',
                border: 0,
                height: '50px',
                background: '#C7E2C8',
                borderRadius: '8px',
                width: '-webkit-fill-available',
              }}
              variant="outlined"
              className="scope-buttons"
            >
              Directions
            </Button>
          </a>
        </Grid>
        <Grid item xs={4}>
          <Button
            sx={{
              color: '#000000',
              background: '#FEEC9A',
              borderRadius: '8px',
              border: 0,
              height: '50px',
              width: '-webkit-fill-available',
            }}
            variant="outlined"
            className="scope-buttons"
            onClick={(e) => handleClick('/site-restriction')}
          >
            Site Restrictions
          </Button>
        </Grid>
      </Grid>
      <hr></hr>

      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Grid>
          {documents.workOrders.length > 0 && (
            <div className="pdf-heading">Work Orders</div>
          )}
          {documents.workOrders.map((result, index) => (
            <PdfBox result={result} key={index} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Grid>
          {documents.deliveryTickets.length > 0 && (
            <div className="pdf-heading">Delivery Tickets</div>
          )}
          {documents.deliveryTickets.map((result, index) => (
            <PdfBox result={result} key={index} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Grid>
          {documents.deliveryTickets.length > 0 && (
            <div className="pdf-heading">Prints</div>
          )}
          {documents.prints.map((result, index) => (
            <PdfBox result={result} key={index} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Grid>
          {documents.msds.length > 0 && <div className="pdf-heading">MSDS</div>}
          {documents.msds.map((result, index) => (
            <PdfBox result={result} key={index} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
