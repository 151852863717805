import React, { useState, useEffect } from 'react';
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Input,
  Box,
  TextField,
  LinearProgress,
  Button,
} from '@mui/material';
import { MobileTimePicker, DesktopTimePicker, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import SignaturePad from 'react-signature-pad-wrapper';
import '../../App.css';
import { useMsal } from '@azure/msal-react';
import Layout from '../../components/partials/layout/Layout';
import { loadTimeCard } from '../../redux/actions/timeCardActions';
import { startDPRTimer } from '../../utils/timerUtils';
import { connect } from 'react-redux';
import { getTimeCards } from '../../redux/selectors/timeCard';
import '../../App.css';
import FobScanIcon from '../../images/svg/fobScanIcon.svg';
import { alpha, styled } from '@mui/material/styles';
import { apiCall, callApiWithToken, postTimeCard } from '../../fetch';
import configureStore from '../../redux/reducers/configureStore';
import { ACTION_TYPES } from '../../redux/constants/action-types';
import { getUser } from '../../redux/selectors/homePage';
import moment from 'moment';
import Modal from '../../components/modals/Modal';
import { useStateIfMounted } from 'use-state-if-mounted';
import { protectedResources } from '../../authConfig';
import ActionAlerts from '../../components/alerts/Toasts';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#43A047',
    '&:hover': {
      backgroundColor: alpha('#43A047', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#43A047',
  },
}));

export function TimeClock(props) {
  const { timeCards, user } = props;
  const { instance, accounts } = useMsal();
  const [employeeChecked, setEmployeeChecked] = useState(true);
  const [ipChecked, setIpChecked] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [ips, setIps] = useState([]);
  const store = configureStore();
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const [signatureEmployee, setSignatureEmployee] = useState(null);
  const [signatureRef, setSignatureRef] = useState('');
  const [scan, setScan] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [alertText, setAlertText] = useState(
    'All time records for the employee are entered'
  );
  const schedulerId = localStorage.getItem('schedulerId');
  const bbUserId = localStorage.getItem('bbUserId');

  const createReminder = async () => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    const reminders = await callApiWithToken(response.accessToken, {
      url: `https://api-dev.manninggroup.com/ReminderNotification/${schedulerId}`,
      method: 'GET',
    });
    let reminderTypeIds = [];
    reminders.map((item) => {
      reminderTypeIds.push(item.reminderNotificationTypeId);
    });
    reminderTypeIds = [...new Set(reminderTypeIds)];

    // clock in reminder
    if (!reminderTypeIds.includes(2)) {
      setNotification(true);
      await callApiWithToken(response.accessToken, {
        url: `https://api-dev.manninggroup.com/ReminderNotification`,
        method: 'POST',
        body: {
          id: 0,
          schedulerId: Number(schedulerId),
          blackberryuserId: Number(bbUserId),
          reminderNotificationTypeId: 2,
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 30000);
      const response = await apiCall({
        url: `https://api-dev.manninggroup.com/Timecards/${schedulerId}`,
        method: 'GET',
      });
      const timeSegments = response.timecards ? response.timecards : [];
      const userResponse = await apiCall({
        url: 'https://api-dev.manninggroup.com/Foreman/2',
        method: 'GET',
      });
      let userSchedule;
      userResponse.schedules.map((schedule) => {
        if (schedule.id == schedulerId) {
          userSchedule = schedule;
        }
      });

      const timeSegmentHashMap = timeSegments.reduce(function (map, obj) {
        map[obj.mobileworkerId] = obj;
        return map;
      }, {});
      let scheduleWorkers =
        userSchedule && userSchedule.foremanScheduleWorkers
          ? userSchedule.foremanScheduleWorkers
          : [];
      const employeesData = [];
      const ipsData = [];
      scheduleWorkers = scheduleWorkers.map((scheduleWorker) => {
        console.log({ scheduleWorker });
        if (timeSegmentHashMap[scheduleWorker.mobileWorkerId]) {
          scheduleWorker.timeSegments =
            timeSegmentHashMap[scheduleWorker.mobileWorkerId];
        }
        if (Number(scheduleWorker.fobNumber) > 10000) {
          ipsData.push(scheduleWorker);
        } else {
          employeesData.push(scheduleWorker);
        }
        return scheduleWorker;
      });
      setEmployees(employeesData);
      setIps(ipsData);

      createReminder();
      return () => clearInterval(timer);
    })();
  }, []);

  const fobScan = () => {
    setScan(true);
    setCurrentTime(new Date());
    setAlertType('success');
    setAlertText('Fob scan started');
    setTimeout(() => {
      setAlert(false);
    }, 1000);
    setAlert(true);
    const abortController = new AbortController();
    const ndef = new NDEFReader();
    ndef
      .scan({ signal: abortController.signal })
      .then(() => {
        console.log('Scan started successfully.');
        setScan(true);
        ndef.onreadingerror = (event) => {
          setAlertType('error');
          setAlertText(
            'Error! Cannot read data from the NFC tag. Try a different one?'
          );
          setTimeout(() => {
            setAlert(false);
          }, 2000);
          setAlert(true);
          setScan(false);
        };
        ndef.onreading = ({ message, serialNumber }) => {
          const record = message.records[0];
          if (record.recordType == 'text') {
            const textDecoder = new TextDecoder(record.encoding);
            setScan(false);
            abortController.abort();
            setTimeEntry(textDecoder.decode(record.data));
          }
        };
      })
      .catch((error) => {
        setAlertType('error');
        setAlertText(`Error! Scan failed to start: ${error}.`);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setAlert(true);
        setScan(false);
      });
  };

  useEffect(() => {
    const isRefreshed = localStorage.getItem('refreshed');
    const isReOpened = localStorage.getItem('reOpened');
    if (isRefreshed) {
      let isDprReminderRunning = localStorage.getItem('dprReminder');
      isDprReminderRunning = JSON.parse(isDprReminderRunning);
      var diff = Math.abs(new Date() - new Date(isDprReminderRunning.time));
      let minutes = Math.floor(diff / 1000 / 60);
      localStorage.removeItem('refreshed');
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        const reminders = await callApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification/${isDprReminderRunning.schedulerId}`,
          method: 'GET',
        });
        if (reminders && reminders.length > 0) {
          let data = {
            id: 0,
            schedulerId: Number(reminders[0].schedulerId),
            blackberryuserId: Number(reminders[0].blackberryuserId),
            reminderNotificationTypeId: 3,
          };
          if (minutes > 5) {
            setNotification(true);
            startDPRTimer(response.accessToken, data);
          } else {
            let timeRemaining = Math.floor((5 - minutes) * 60 * 1000);
            setTimeout(() => {
              setNotification(true);
              startDPRTimer(response.accessToken, data);
            }, timeRemaining);
          }
        }
      })();
    }
    if (isReOpened) {
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        localStorage.removeItem('reOpened');
        const reminders = await callApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification/${schedulerId}`,
          method: 'GET',
        });
        const dprReminders = [];
        let latestReminder;
        if (reminders && reminders.length > 0) {
          for (var i in reminders) {
            if (reminders[i].reminderNotificationTypeId == 3) {
              dprReminders.push(reminders[i]);
            }
          }
        }
        if (dprReminders.length > 0) {
          latestReminder = dprReminders[dprReminders.length - 1];
          let minutes = Math.round(
            moment
              .duration(
                moment()
                  .local()
                  .diff(moment.utc(latestReminder.createdAt).local())
              )
              .asMinutes()
          );
          let data = {
            id: 0,
            schedulerId: Number(reminders[0].schedulerId),
            blackberryuserId: Number(reminders[0].blackberryuserId),
            reminderNotificationTypeId: 3,
          };
          if (minutes > 5) {
            setNotification(true);
            startDPRTimer(response.accessToken, data);
          } else {
            let timeRemaining = Math.floor((5 - minutes) * 60 * 1000);
            setTimeout(() => {
              setNotification(true);
              startDPRTimer(response.accessToken, data);
            }, timeRemaining);
          }
        }
      })();
    }
  }, []);

  const setTimeEntry = async (fobNumber) => {
    let updatedEmployees = [...employees];
    const scannedEmployee = updatedEmployees.find(
      (employee) => employee.fobNumber == fobNumber
    );
    if (scannedEmployee.timeSegments.fobserial == null) {
      const mobileTimeEntries =
        scannedEmployee.timeSegments &&
        scannedEmployee.timeSegments.mobiletimeEntries
          ? scannedEmployee.timeSegments.mobiletimeEntries
          : [];
      const timeRecords =
        mobileTimeEntries[0].mobiletimesegmentId == 1
          ? [
              mobileTimeEntries[0].startTime,
              mobileTimeEntries[0].endTime,
              mobileTimeEntries[1].startTime,
              mobileTimeEntries[1].endTime,
            ]
          : [
              mobileTimeEntries[1].startTime,
              mobileTimeEntries[1].endTime,
              mobileTimeEntries[0].startTime,
              mobileTimeEntries[0].endTime,
            ];
      if (timeRecords.includes(null)) {
        let timeRecord = {};
        for (let [index, record] of timeRecords.entries()) {
          if (record == null) {
            timeRecord.index = index;
            break;
          }
        }
        timeRecord.record = moment().format('YYYY-MM-DDTHH:mm:ss');
        let response;
        if (timeRecord.index <= 1) {
          response = await postTimeCard({
            ...mobileTimeEntries[0],
            ...timeRecord,
          });
        } else {
          response = await postTimeCard({
            ...mobileTimeEntries[1],
            ...timeRecord,
          });
        }
        if (response.isError) {
          setAlertType('error');
          setAlertText(response.errorMessage);
          setTimeout(() => {
            setAlert(false);
          }, 2000);
          setAlert(true);
        }
        updatedEmployees = updatedEmployees.map((employee) => {
          if (employee.fobNumber == fobNumber) {
            employee.timeSegments.mobiletimeEntries[
              timeRecord.index <= 1 ? 0 : 1
            ] = response.mobiletimeEntry;
          }
          return employee;
        });
        setEmployees(updatedEmployees);
        validateForDprReminder();
      } else {
        setAlertType('alert');
        setAlertText(`All time records are entered`);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setAlert(true);
      }
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    p: 4,
  };

  const signatureModalBody = () => {
    return (
      <Box sx={style}>
        <Grid container sx={{ marginBottom: '30px' }}>
          <Grid item xs={6} align="left" className="signature-text">
            Signature
          </Grid>
          <Grid item xs={6} align="right">
            <CloseIcon
              onClick={() => setOpenModal(false)}
              sx={{ color: '#1E417C' }}
            />
          </Grid>
        </Grid>
        <Grid sx={{ marginBottom: '20px' }}>
          <p className="signature-labels">Employee Name</p>
          <Input
            readOnly={true}
            size="small"
            variant="standard"
            value={
              signatureEmployee
                ? signatureEmployee.firstName + ' ' + signatureEmployee.lastName
                : ''
            }
            className="signature-values"
            disableUnderline={true}
          />
        </Grid>
        <Grid>
          <p className="signature-labels">Employee Signature</p>
          <Box className="signature-box">
            <SignaturePad ref={(ref) => setSignatureRef(ref)} />
          </Box>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            className="modalSave"
            onClick={() => captureSignature()}
          >
            Submit
          </Button>
        </Grid>
      </Box>
    );
  };

  const captureSignature = () => {
    apiCall({
      url: 'https://api-dev.manninggroup.com/Timecard/Signature',
      method: 'POST',
      body: {
        mobiletimeId: signatureEmployee.timeSegments?.id,
        signaturedata: signatureRef.toDataURL(),
      },
    }).then((resp) => {
      let fobserial = signatureEmployee.fobNumber;
      apiCall({
        url: 'https://api-dev.manninggroup.com/Timecard/Fob',
        method: 'POST',
        body: {
          fobserial,
          mobiletimeId: signatureEmployee.timeSegments?.id,
        },
      }).then((response) => {
        fobserial =
          response.isError == false ? response.mobiletime.fobserial : null;
        let employeesData = [...employees];
        employeesData = employeesData.map((employee) => {
          if (employee.fobNumber == signatureEmployee.fobNumber) {
            employee.timeSegments.fobserial = fobserial;
          }
          return employee;
        });
        setEmployees(employeesData);
        setSignatureEmployee(null);
        setSignatureRef('');
        setOpenModal(false);
      });
    });
  };

  const TimePickerComponent = (props) => {
    const time =
      props.timeEntry != null ? moment(props.timeEntry).format('HH:mm') : '';
    const fobNumber = props.rowId;
    return props.manualMode == false ? (
      <Input
        readOnly={true}
        placeholder="HH : MM"
        size="small"
        variant="standard"
        className="custom-ui"
        value={time}
        disableUnderline={true}
      />
    ) : (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* <DesktopTimePicker
          ampm={false}
          value={value}
          showToolbar={false}
          views={['hours', 'minutes']}
          inputFormat="HH:mm"
          mask="__:__"
          cancelText={null}
          onChange={(newValue) => {
            // setValue(newValue);
          }}
          onAccept={(newValue) => {
            console.log(newValue);
          }}
          disableCloseOnSelect={false}
          openTo="hours"
          showTodayButton={true}
          todayText="Current Time"
          renderInput={(params) => (
            <TextField {...params} variant="standard" className="ip-times" />
          )}
        /> */}

        <MobileTimePicker
          ampm={false}
          showToolbar={false}
          cancelText={null}
          value={props.timeEntry ? moment(props.timeEntry) : props.timeEntry}
          onChange={(newValue) => {
            console.log('changed');
          }}
          onAccept={(newValue) => {
            newValue = moment(newValue).format('HH:mm');
            setTimeEntryManual(newValue, fobNumber, props.mobiletimeEntry);
          }}
          views={['hours', 'minutes']}
          inputFormat="HH:mm"
          showTodayButton={true}
          todayText="Current Time"
          onOpen={() => handleOnOpen(fobNumber)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="HH : MM"
              className="ip-times"
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  const setTimeEntryManual = async (value, fobNumber, mobiletimeEntry) => {
    value = moment(moment().format('YYYY-MM-DD') + ' ' + value);
    setCurrentTime(new Date());
    let updatedWorkers = [];
    if (Number(fobNumber) > 10000) {
      updatedWorkers = [...ips];
    } else {
      updatedWorkers = [...employees];
    }
    const clickedWorker = updatedWorkers.find(
      (worker) => worker.fobNumber == fobNumber
    );
    const mobileTimeEntries =
      clickedWorker.timeSegments && clickedWorker.timeSegments.mobiletimeEntries
        ? clickedWorker.timeSegments.mobiletimeEntries
        : [];
    const timeRecord = mobileTimeEntries.find(
      (entry) => entry.mobiletimesegmentId == mobiletimeEntry.segmentId
    );
    console.log({ mobileTimeEntries });
    const isValid = await validateTimeEntry(
      mobileTimeEntries,
      mobiletimeEntry,
      value
    );
    if (isValid) {
      let updatedTimeRecord = {};
      if (mobiletimeEntry.field == 'startTime') updatedTimeRecord.index = 0;
      if (mobiletimeEntry.field == 'endTime') updatedTimeRecord.index = 1;
      updatedTimeRecord.record = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      let response;
      response = await postTimeCard({
        ...timeRecord,
        ...updatedTimeRecord,
      });
      if (response.isError) {
        setAlertType('error');
        setAlertText(response.errorMessage);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setAlert(true);
      }
      updatedWorkers = updatedWorkers.map((worker) => {
        if (worker.fobNumber == fobNumber) {
          worker.timeSegments.mobiletimeEntries[mobiletimeEntry.segmentId - 1] =
            response.mobiletimeEntry;
        }
        return worker;
      });
      if (Number(fobNumber) > 10000) {
        setIps(updatedWorkers);
      } else {
        setEmployees(updatedWorkers);
      }
      validateForDprReminder();
    } else {
      setAlertType('alert');
      setAlertText(
        'Invalid time entry (all the time entries should be in increasing order)'
      );
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setAlert(true);
    }
  };

  const validateForDprReminder = () => {
    const entries = [];
    let allEntriesAdded = true;
    [...employees, ...ips].map((item) => {
      entries.push(...item.timeSegments.mobiletimeEntries);
    });
    for (let i in entries) {
      if (entries[i].startTime == null || entries[i].endTime == null) {
        allEntriesAdded = false;
        break;
      }
    }
    if (allEntriesAdded) {
      createDPRReminder();
      setNotification(true);
    }
  };

  const createDPRReminder = () => {
    instance
      .acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      })
      .then((response) => {
        startDPRTimer(response.accessToken, {
          id: 0,
          schedulerId,
          blackberryuserId: 2,
          reminderNotificationTypeId: 3,
        });
      });
  };

  const validateTimeEntry = async (
    mobileTimeEntries,
    mobiletimeEntry,
    value
  ) => {
    let isValid = true;
    if (
      mobiletimeEntry.field == 'startTime' &&
      mobiletimeEntry.segmentId == 2
    ) {
      if (
        moment(value) < moment(mobileTimeEntries[0].endTime) ||
        moment(value) > moment(mobileTimeEntries[1].endTime)
      )
        isValid = false;
    }
    if (
      mobiletimeEntry.field == 'startTime' &&
      mobiletimeEntry.segmentId == 1
    ) {
      if (moment(value) > moment(mobileTimeEntries[0].endTime)) isValid = false;
    }
    if (mobiletimeEntry.field == 'endTime' && mobiletimeEntry.segmentId == 1) {
      if (
        moment(value) < moment(mobileTimeEntries[0].startTime) ||
        moment(value) > moment(mobileTimeEntries[1].startTime)
      )
        isValid = false;
    }
    if (mobiletimeEntry.field == 'endTime' && mobiletimeEntry.segmentId == 2) {
      if (moment(value) < moment(mobileTimeEntries[1].startTime))
        isValid = false;
    }
    return isValid;
  };

  const handleOnOpen = (rowId) => {
    const previousElement = document.getElementsByClassName('highlight-row')[0];
    if (previousElement) previousElement.classList.remove('highlight-row');
    document
      .getElementsByClassName(`worker${rowId}`)[0]
      .classList.add('highlight-row');
  };

  const handleManualMode = (worker) => (event) => {
    if (event.target.checked == false) {
      setSignatureEmployee(worker);
      setOpenModal(true);
    } else {
      setAlertType('alert');
      setAlertText('Cannot revert back to FOB mode');
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setAlert(true);
    }
  };

  return (
    <Layout heading="Time Clock" notification={notification} scan={scan}>
      <ActionAlerts
        open={alert}
        type={alertType}
        description={alertText}
        handleClose={() => setAlert(false)}
      />
      <Grid item xs={12} sx={{ marginBottom: '30px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            item
            xs={4}
          >
            <div className="worker-heading">Employees</div>
            <GreenSwitch
              sx={{ color: '#43A047' }}
              onChange={() => setEmployeeChecked(!employeeChecked)}
              checked={employeeChecked}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="current-time">
              Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
            </div>
          </Grid>
          <Grid item xs={2} align="center" sx={{ marginBottom: '10px' }}>
            <div className="m-t-r10" onClick={fobScan}>
              <img src={FobScanIcon} alt="fobScan-icon" /> <br />
              <span className="manning-blue-text">FOB SCANNER</span>
            </div>
          </Grid>
        </Grid>
        {employeeChecked && (
          <TableContainer
            sx={{ borderRadius: '8px', boxShadow: 'none' }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-heading-clock" align="center">
                    FOB
                  </TableCell>
                  <TableCell
                    className="table-heading-clock table-cell-border"
                    style={{ minWidth: 250 }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className="table-heading-clock"
                    align="center"
                    colSpan={4}
                  >
                    Record
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    background: '#ECF1F4',
                  }}
                >
                  <TableCell align="center" className="pt-1 pb-1"></TableCell>
                  <TableCell align="center" className="pt-1 pb-1"></TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    In (BOD)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    Out (lunch)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    In (lunch)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    Out (EOD)
                  </TableCell>
                </TableRow>
                {employees.map((worker) => (
                  <TableRow
                    key={worker.id}
                    className={'worker' + worker.fobNumber}
                  >
                    <TableCell align="center">
                      <Checkbox
                        color="success"
                        checked={
                          worker.timeSegments.fobserial == null ? true : false
                        }
                        onChange={handleManualMode(worker)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-border"
                    >
                      {worker.firstName + ' ' + worker.lastName}
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={
                          worker.timeSegments.fobserial == null ? false : true
                        }
                        timeEntry={
                          worker.timeSegments?.mobiletimeEntries[0]?.startTime
                        }
                        rowId={worker.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            worker.timeSegments?.mobiletimeEntries[0]
                              .mobiletimesegmentId,
                          field: 'startTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={
                          worker.timeSegments.fobserial == null ? false : true
                        }
                        timeEntry={
                          worker.timeSegments?.mobiletimeEntries[0]?.endTime
                        }
                        rowId={worker.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            worker.timeSegments?.mobiletimeEntries[0]
                              .mobiletimesegmentId,
                          field: 'endTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={
                          worker.timeSegments.fobserial == null ? false : true
                        }
                        timeEntry={
                          worker.timeSegments?.mobiletimeEntries[1]?.startTime
                        }
                        rowId={worker.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            worker.timeSegments?.mobiletimeEntries[1]
                              .mobiletimesegmentId,
                          field: 'startTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={
                          worker.timeSegments.fobserial == null ? false : true
                        }
                        timeEntry={
                          worker.timeSegments?.mobiletimeEntries[1]?.endTime
                        }
                        rowId={worker.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            worker.timeSegments?.mobiletimeEntries[1]
                              .mobiletimesegmentId,
                          field: 'endTime',
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          align="left"
          sx={{ marginBottom: '15px' }}
        >
          <Grid
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            item
            xs={8}
          >
            <div className="worker-heading">Installation Partners</div>
            <GreenSwitch
              sx={{ color: '#43A047' }}
              onChange={() => setIpChecked(!ipChecked)}
              checked={ipChecked}
            />
          </Grid>
        </Grid>
        {ipChecked && (
          <TableContainer
            sx={{ borderRadius: '8px', boxShadow: 'none' }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="table-heading-clock table-cell-border"
                    style={{ minWidth: '325px' }}
                    colSpan={2}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className="table-heading-clock"
                    align="center"
                    colSpan={4}
                  >
                    Record
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    background: '#ECF1F4',
                  }}
                >
                  <TableCell align="center" className="pt-1 pb-1"></TableCell>
                  <TableCell align="center" className="pt-1 pb-1"></TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    In (BOD)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    Out (lunch)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    In (lunch)
                  </TableCell>
                  <TableCell align="center" className="pt-1 pb-1">
                    Out (EOD)
                  </TableCell>
                </TableRow>
                {ips.map((ip) => (
                  <TableRow key={ip.id} className={'worker' + ip.fobNumber}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-border"
                      colSpan={2}
                    >
                      {ip.firstName + ' ' + ip.lastName}
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={true}
                        timeEntry={
                          ip.timeSegments?.mobiletimeEntries[0]?.startTime
                        }
                        rowId={ip.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            ip.timeSegments?.mobiletimeEntries[0]
                              .mobiletimesegmentId,
                          field: 'startTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={true}
                        timeEntry={
                          ip.timeSegments?.mobiletimeEntries[0]?.endTime
                        }
                        rowId={ip.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            ip.timeSegments?.mobiletimeEntries[0]
                              .mobiletimesegmentId,
                          field: 'endTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={true}
                        timeEntry={
                          ip.timeSegments?.mobiletimeEntries[1]?.startTime
                        }
                        rowId={ip.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            ip.timeSegments?.mobiletimeEntries[1]
                              .mobiletimesegmentId,
                          field: 'startTime',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TimePickerComponent
                        manualMode={true}
                        timeEntry={
                          ip.timeSegments?.mobiletimeEntries[1]?.endTime
                        }
                        rowId={ip.fobNumber}
                        mobiletimeEntry={{
                          segmentId:
                            ip.timeSegments?.mobiletimeEntries[1]
                              .mobiletimesegmentId,
                          field: 'endTime',
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      {openModal && (
        <Modal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          body={signatureModalBody()}
        />
      )}
    </Layout>
  );
}

function mapStateToProps(store) {
  const timeCards = getTimeCards(store);
  const userResponse = getUser(store);
  return {
    user: userResponse ? userResponse : {},
    timeCards: timeCards.length > 0 ? timeCards : [],
  };
}

const mapDispatchToProps = {
  loadTimeCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeClock);
