import React from 'react';
import Header from '../header/Header.jsx';
import SubHeader from '../header/Sidebar.jsx';
import Footer from '../footer/Footer.jsx';

export default function Layout(props) {
  return (
    <React.Fragment>
      <Header
        heading={props.heading ? props.heading : ''}
        notification={props.notification ? props.notification : false}
        scan={props.scan ? props.scan : false}
      />
      <SubHeader />
      <div className="side-content">
        <div className="left-sidecontent">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
