import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="34"
      fill="none"
      viewBox="0 0 31 34"
    >
      <path
        fill="#1E417C"
        d="M30.659 9.194L22.37.58a.642.642 0 00-.45-.191H9.556a2.52 2.52 0 00-2.508 2.508v8.766H2.045a1.885 1.885 0 00-1.882 1.881v9.393c0 1.036.845 1.881 1.882 1.881h5.003v5.63a2.52 2.52 0 002.508 2.509h18.772a2.502 2.502 0 002.508-2.509V9.644a.664.664 0 00-.177-.45zM22.07 2.078l6.803 7.075H22.07V2.078zM2.03 23.55a.634.634 0 01-.626-.627V13.53c0-.34.286-.627.627-.627h16.904c.34 0 .627.287.627.627v9.393c0 .34-.286.627-.627.627H2.03zm27.552 6.885a1.25 1.25 0 01-1.254 1.254H9.556a1.251 1.251 0 01-1.254-1.254v-5.63h10.646a1.885 1.885 0 001.882-1.882V13.53a1.885 1.885 0 00-1.881-1.88H8.301V2.882c0-.696.559-1.255 1.254-1.255h11.26v8.139c0 .34.287.627.627.627h8.139v20.04z"
      ></path>
      <path
        fill="#1E417C"
        d="M28.328 33.12H9.556a2.677 2.677 0 01-2.672-2.672V24.98h-4.84A2.042 2.042 0 010 22.936v-9.392c0-1.132.913-2.045 2.045-2.045h4.84V2.91c0-1.472 1.199-2.672 2.671-2.672h12.365c.218 0 .422.096.572.246l8.289 8.615c.15.15.218.341.218.56V30.46c0 1.459-1.2 2.659-2.672 2.659zM2.031 11.826c-.94 0-1.704.763-1.704 1.704v9.393c0 .94.764 1.704 1.704 1.704h5.18v5.807a2.34 2.34 0 002.332 2.331h18.771a2.342 2.342 0 002.345-2.33V9.63a.432.432 0 00-.123-.313L22.248.702a.423.423 0 00-.327-.136H9.556a2.33 2.33 0 00-2.331 2.33v8.93H2.03zm26.297 20.04H9.556c-.79 0-1.431-.641-1.431-1.418V24.64h10.81c.94 0 1.704-.763 1.704-1.704v-9.392c0-.941-.763-1.705-1.704-1.705H8.125V2.91c0-.79.64-1.431 1.431-1.431h11.438V9.78c0 .245.204.45.45.45h8.302v20.203c0 .79-.64 1.431-1.418 1.431zM8.466 24.98v5.467c0 .6.49 1.077 1.09 1.077h18.772c.6 0 1.09-.491 1.09-1.077V10.585h-7.96a.791.791 0 01-.791-.79V1.833H9.57c-.6 0-1.09.491-1.09 1.091v8.588h10.469c1.131 0 2.045.914 2.045 2.045v9.393a2.042 2.042 0 01-2.045 2.045H8.466v-.014zm10.47-1.254H2.03a.791.791 0 01-.79-.79v-9.393c0-.437.354-.791.79-.791h16.904c.437 0 .791.354.791.79v9.393c.014.436-.354.791-.79.791zM2.03 13.08a.454.454 0 00-.45.45v9.393c0 .245.205.45.45.45h16.904c.246 0 .45-.205.45-.45V13.53a.454.454 0 00-.45-.45H2.031zm27.251-3.749h-7.375V1.67l7.375 7.661zm-7.048-.34h6.23l-6.23-6.476V8.99z"
      ></path>
      <path
        fill="#1E417C"
        d="M7.647 16.286a1.39 1.39 0 00-.777-.532c-.204-.054-.627-.082-1.295-.082H3.83v5.372h1.09v-2.032h.71c.49 0 .872-.027 1.131-.082.191-.04.382-.122.559-.258.177-.123.341-.3.45-.532.123-.232.177-.505.177-.832 0-.409-.095-.763-.3-1.022zm-.94 1.472a.657.657 0 01-.341.26c-.15.054-.437.08-.86.08h-.599v-1.526h.532c.395 0 .654.014.777.041.177.027.327.11.436.232.11.122.177.286.177.49 0 .164-.04.3-.122.423zM13.168 17.145a2.434 2.434 0 00-.518-.873 1.813 1.813 0 00-.804-.49c-.232-.069-.573-.11-1.023-.11h-1.99v5.372h2.045c.395 0 .722-.041.968-.11.327-.109.572-.245.763-.422.246-.245.436-.56.573-.954.109-.314.163-.696.163-1.145 0-.505-.054-.928-.177-1.268zm-1.05 2.208c-.068.245-.163.41-.286.518a.944.944 0 01-.436.218c-.137.041-.355.055-.655.055h-.804v-3.558h.49c.45 0 .737.013.887.054.204.041.368.123.504.26.137.122.232.3.314.517.068.218.11.546.11.955-.015.409-.056.736-.124.981zM17.968 16.586v-.914h-3.695v5.372h1.091v-2.277h2.25v-.913h-2.25v-1.268h2.604z"
      ></path>
    </svg>
  );
}

export default Icon;
