import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GoalsPage from './project-goals/Goals';
import '../../App.css';
import { useMsal } from '@azure/msal-react';
import Layout from '../../components/partials/layout/Layout';
import ProjectScope from './project-scope/index';
import { loadUser } from '../../redux/actions/userActions';
import { saveWorkOrder } from '../../redux/actions/workOrderActions';
import { connect } from 'react-redux';
import { getUser } from '../../redux/selectors/homePage';
import { protectedResources } from '../../authConfig';
import Punchlist from './project-punchlist/Punchlist';
import DPR from './project-dpr/index';
import { callApiWithToken } from '../../fetch';
import { startDPRTimer } from '../../utils/timerUtils';

export function ProjectBinder(props) {
  const selectedTab = localStorage.getItem('selectedTab');
  const [selectedButton, setSelectedButton] = useState(
    selectedTab &&
      [
        'project-scope',
        'project-goals',
        'project-punchlist',
        'project-photos',
      ].includes(selectedTab)
      ? selectedTab
      : 'project-scope'
  );
  const { loadUser } = props;
  const { instance, accounts } = useMsal();
  const mode = localStorage.getItem('mode');
  const [notification, setNotification] = useState(false);
  const schedulerId = Number(localStorage.getItem('schedulerId'));
  const bbUserId = Number(localStorage.getItem('bbUserId'));
  const scheduleStartDate = localStorage.getItem('scheduleStartDate');

  useEffect(async () => {
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    loadUser(response.accessToken, accounts[0].username);
    createReminders(response.accessToken);
  }, []);

  const createReminders = async (token) => {
    const reminders = await callApiWithToken(token, {
      url: `https://api-dev.manninggroup.com/ReminderNotification/${schedulerId}`,
      method: 'GET',
    });
    let reminderTypeIds = [];
    reminders.map((item) => {
      reminderTypeIds.push(item.reminderNotificationTypeId);
    });
    reminderTypeIds = [...new Set(reminderTypeIds)];
    const response = await instance.acquireTokenSilent({
      ...protectedResources.apiForeman,
      account: accounts[0],
    });
    // safety reminder
    const showSafetyReminder =
      new Date(scheduleStartDate).toDateString() >= new Date().toDateString();
    if (showSafetyReminder && !reminderTypeIds.includes(1)) {
      setNotification(true);
      callApiWithToken(response.accessToken, {
        url: `https://api-dev.manninggroup.com/ReminderNotification`,
        method: 'POST',
        body: {
          id: 0,
          schedulerId,
          blackberryuserId: bbUserId,
          reminderNotificationTypeId: 1,
        },
      });
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedTab', selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    const isRefreshed = localStorage.getItem('refreshed');
    if (isRefreshed) {
      let isDprReminderRunning = localStorage.getItem('dprReminder');
      isDprReminderRunning = JSON.parse(isDprReminderRunning);
      var diff = Math.abs(new Date() - new Date(isDprReminderRunning.time));
      let minutes = Math.floor(diff / 1000 / 60);
      localStorage.removeItem('refreshed');
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        const reminders = await callApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification/${isDprReminderRunning.schedulerId}`,
          method: 'GET',
        });
        if (reminders && reminders.length > 0) {
          let data = {
            id: 0,
            schedulerId: Number(reminders[0].schedulerId),
            blackberryuserId: Number(reminders[0].blackberryuserId),
            reminderNotificationTypeId: 3,
          };
          if (minutes > 5) {
            setNotification(true);
            startDPRTimer(response.accessToken, data);
          } else {
            let timeRemaining = Math.floor((5 - minutes) * 60 * 1000);
            setTimeout(() => {
              setNotification(true);
              startDPRTimer(response.accessToken, data);
            }, timeRemaining);
          }
        }
      })();
    }
  }, []);

  return (
    <Layout heading="Project Binder" notification={notification}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <Button
            onClick={() => setSelectedButton('project-scope')}
            variant="outlined"
            className={
              selectedButton === 'project-scope'
                ? 'orange-bg mt10'
                : 'unselected-button mt10'
            }
          >
            PROJECT SCOPE
          </Button>
          <Button
            onClick={() => setSelectedButton('project-goals')}
            variant="outlined"
            className={
              selectedButton === 'project-goals'
                ? 'orange-bg'
                : 'unselected-button'
            }
          >
            PROJECT GOALS
          </Button>
          <Button
            onClick={() => setSelectedButton('project-punchlist')}
            variant="outlined"
            className={
              selectedButton === 'project-punchlist'
                ? 'orange-bg'
                : 'unselected-button'
            }
          >
            PUNCH LIST
          </Button>
          {mode != 'plan' && (
            <Button
              onClick={() => setSelectedButton('project-photos')}
              variant="outlined"
              className={
                selectedButton === 'project-photos'
                  ? 'orange-bg'
                  : 'unselected-button'
              }
            >
              PROJECT PHOTOS &<br /> PROGRESS REPORT
            </Button>
          )}
        </Grid>
        {selectedButton === 'project-goals' && <GoalsPage />}
        {selectedButton === 'project-scope' && <ProjectScope />}
        {selectedButton === 'project-punchlist' && <Punchlist />}
        {selectedButton === 'project-photos' && <DPR />}
      </Grid>
    </Layout>
  );
}

function mapStateToProps(store) {
  const user = getUser(store);
  return {
    user,
    workOrders: user.workOrders ? user.workOrders : [],
  };
}

const mapDispatchToProps = {
  loadUser,
  saveWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBinder);
