import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '../../../images/png/add-icon.png';
import '../../../App.css';
import Modal from '../../../components/modals/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Loader from '../../../components/loaders';
import { apiCall } from '../../../fetch';
import moment from 'moment';
import { useStateIfMounted } from 'use-state-if-mounted';
import ActionAlerts from '../../../components/alerts/Toasts';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  p: 4,
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Punchlist() {
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [punchList, setPunchList] = useState([]);
  const [textError, setTextError] = useState(false);
  const [open, setOpen] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertText, setAlertText] = useState('Punch item added successfully');
  const workOrderId = Number(localStorage.getItem('workOrderId'));
  const blackberryUserId = Number(localStorage.getItem('bbUserId'));

  const handleClose = () => {
    setOpenModal(false);
    setTextError(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    apiCall({
      url: `https://api-dev.manninggroup.com/Punch/${workOrderId}`,
      method: 'GET',
    }).then((punchListResponse) => {
      setPunchList(punchListResponse);
      setOpen(false);
    });
    return () => clearInterval(timer);
  }, []);

  const addPunchItem = async (e) => {
    try {
      const punchItem = document.getElementById('punch-text').value;
      if (punchItem.trim() != '') {
        const response = await apiCall({
          url: `https://api-dev.manninggroup.com/Punch`,
          method: 'POST',
          body: {
            workOrderId,
            notes: punchItem,
            createdBy: blackberryUserId,
            created: new Date().toISOString(),
            resolvedBy: null,
            resolvedDate: null,
            resolutionNotes: null,
          },
        });
        punchList.push(response);
        setPunchList(punchList);
        setOpenModal(false);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
        setAlert(true);
      } else {
        setTextError(true);
      }
    } catch (error) {
      setAlertType('error');
      setAlertText('Error! Could not add the punch item');
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setAlert(true);
    }
  };

  const handlePunchItemChange = (punchItem) => (event) => {
    let updatedPunchList = [...punchList];
    try {
      if (event.target.checked) {
        apiCall({
          url: `https://api-dev.manninggroup.com/Punch/${punchItem.id}/Complete/${blackberryUserId}`,
          method: 'POST',
        });
        updatedPunchList = updatedPunchList.map((item) => {
          if (punchItem.id == item.id) {
            item.resolvedDate = new Date().toISOString();
          }
          return item;
        });
      } else {
        apiCall({
          url: `https://api-dev.manninggroup.com/Punch/${punchItem.id}/UnComplete`,
          method: 'POST',
        });
        updatedPunchList = updatedPunchList.map((item) => {
          if (punchItem.id == item.id) {
            item.resolvedDate = null;
          }
          return item;
        });
      }
      setPunchList(updatedPunchList);
    } catch (error) {
      setAlertType('error');
      setAlertText('Error! Could not change the status of punch item');
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setAlert(true);
    }
  };

  const addItems = () => {
    return (
      <Box sx={style}>
        <TextField
          error={textError ? true : false}
          id="punch-text"
          label="Punch"
          variant="standard"
          fullWidth={true}
        />
        <Button
          variant="contained"
          className="modalSave"
          onClick={(e) => addPunchItem(e)}
        >
          Add
        </Button>
      </Box>
    );
  };

  return (
    <Grid item xs={10}>
      <ActionAlerts
        open={alert}
        type={alertType}
        description={alertText}
        handleClose={() => setAlert(false)}
      />
      <Loader open={open} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <div className="project-goals-heading">Punch List</div>
        </Grid>
        <Grid item xs={6}>
          <div className="current-time">
            Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
          </div>
        </Grid>
        <Grid item xs={3} align="right">
          <div onClick={() => handleOpen()} className="m-t-r10">
            <img src={AddIcon} alt="add-icon" /> <br />
            Add
          </div>
        </Grid>
      </Grid>
      <div className="card-checkbox mt20">
        {punchList.length ? (
          <FormGroup>
            {punchList.map((punchItem, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    {...label}
                    checked={!punchItem.resolvedDate ? false : true}
                    color="success"
                    onChange={handlePunchItemChange(punchItem)}
                  />
                }
                label={punchItem.notes}
              />
            ))}
          </FormGroup>
        ) : (
          <div className="margin10">Please add a punch item</div>
        )}
      </div>
      {openModal && (
        <Modal
          open={openModal}
          handleClose={() => handleClose()}
          body={addItems()}
        />
      )}
    </Grid>
  );
}
