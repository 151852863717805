import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Paper,
  Button,
  Switch,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { apiCall, callApiWithToken, callPdfApiWithToken } from '../../../fetch';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '../../../images/svg/closeImageIcon.svg';
import CameraIcon from '../../../images/svg/cameraIcon.svg';
import moment from 'moment';
import { FOREMAN_NOTE_TYPE } from '../../../constants/dprConstants';
import Loader from '../../../components/loaders';
import Alert from '../../../components/alerts/Alert';
import { protectedResources } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { stopDPRTimer } from '../../../utils/timerUtils';
import axios from 'axios';
import { useStateIfMounted } from 'use-state-if-mounted';
import Modal from '../../../components/modals/Modal';
import SignaturePad from 'react-signature-pad-wrapper';
import ModalCloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ActionAlerts from '../../../components/alerts/Toasts';

function index() {
  const [imageDataUrls, setimageDataUrls] = React.useState([]);
  const [newFiles, setNewFiles] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [existingFileDetails, setExistingFileDetails] = React.useState([]);
  const { instance, accounts } = useMsal();
  const [loaderOpen, setLoaderOpen] = React.useState(true);
  const [DamagedProduct, setDamagedProduct] = React.useState('');
  const [ReturnedProduct, setReturnedProduct] = React.useState('');
  const [DPRDescription, setDPRDescription] = React.useState('');
  const [alertType, setAlertType] = React.useState('success');
  const [currentTime, setCurrentTime] = useStateIfMounted(new Date());
  const schedulerId = Number(localStorage.getItem('schedulerId'));
  const workorderId = Number(localStorage.getItem('workOrderId'));
  const bbUserId = localStorage.getItem('bbUserId');
  const [signatureRef, setSignatureRef] = useState('');
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedEquipments, setCheckedEquipments] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState('Report saved successfully');
  const [calledManagerChecked, setcalledManagerChecked] = useState(false);
  const navigate = useNavigate();

  const handleCapture = (target) => {
    const targetFiles = [...newFiles];
    if (target.files) {
      let files = [...imageDataUrls];
      if (target.files.length !== 0) {
        const file = target.files[0];
        targetFiles.push(target.files[0]);
        const newUrl = URL.createObjectURL(file);
        files.push(newUrl);
        setimageDataUrls(files);
        setNewFiles(targetFiles);
      }
    }
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#43A047',
      '&:hover': {
        backgroundColor: alpha('#43A047', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#43A047',
    },
  }));
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '80%',
    width: '50%',
    overflow: 'scroll',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    p: 4,
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);
    const dataUrls = [];
    (async () => {
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });
      const files = await callApiWithToken(response.accessToken, {
        url: `https://api-dev.manninggroup.com/ProjectBinder/${workorderId}/${schedulerId}/documenttype/5`,
        method: 'GET',
      });
      setExistingFileDetails(files);
      for (const file of files) {
        const url = await callPdfApiWithToken(response.accessToken, {
          url: `https://api-dev.manninggroup.com/ProjectBinder/${file.fileId}`,
          method: 'GET',
        });
        dataUrls.push(`data:${file.mimeType};base64,${url}`);
      }
      setimageDataUrls(dataUrls);
    })();

    apiCall({
      url: `https://api-dev.manninggroup.com/ForemanNote/${schedulerId}`,
      method: 'GET',
    }).then((response) => {
      if (response && response.length > 0) {
        response.forEach((element) => {
          if (element.foremannotetypeId == FOREMAN_NOTE_TYPE.RETURNED_PRODUCT)
            setReturnedProduct(element.noteText);
          if (element.foremannotetypeId == FOREMAN_NOTE_TYPE.DAMAGED_PRODUCT)
            setDamagedProduct(element.noteText);
          if (element.foremannotetypeId == FOREMAN_NOTE_TYPE.DESCRIPTION)
            setDPRDescription(element.noteText);
        });
      }
      setLoaderOpen(false);
    });
    return () => clearInterval(timer);
  }, []);

  const removeImage = async (index) => {
    const newImageArray = [...imageDataUrls];
    if (index < existingFileDetails.length) {
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });
      await axios({
        url: `https://api-dev.manninggroup.com/ProjectBinder/${existingFileDetails[index].fileId}`,
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + response.accessToken,
        },
      });
      const updateExistingFiles = [...existingFileDetails];
      updateExistingFiles.splice(index);
      setExistingFileDetails(updateExistingFiles);
    } else {
      const updateNewFiles = [...newFiles];
      updateNewFiles.splice(index - existingFileDetails.length, 1);
      setNewFiles(updateNewFiles);
    }
    newImageArray.splice(index, 1);
    setimageDataUrls(newImageArray);
  };

  const saveDPR = () => {
    setAlertType('success');
    setAlertText('Report saved successfully');
    saveReport();
    setTimeout(function () {
      setLoaderOpen(false);
      setAlert(false);
    }, 2000);
    setAlert(true);
  };

  const saveReport = () => {
    setLoaderOpen(true);
    let formData = new FormData();

    const requestObj = {
      id: 0,
      schedulerId,
      workorderId,
    };
    apiCall({
      url: `https://api-dev.manninggroup.com/ForemanNote/ReturnedProduct`,
      method: 'POST',
      body: { ...requestObj, noteText: ReturnedProduct },
    }).then((response) => {
      if (response && response.noteText) {
        setReturnedProduct(response.noteText);
      }
    });
    apiCall({
      url: `https://api-dev.manninggroup.com/ForemanNote/DamagedProduct`,
      method: 'POST',
      body: { ...requestObj, noteText: DamagedProduct },
    }).then((response) => {
      if (response) {
        setDamagedProduct(response.noteText);
      }
    });
    apiCall({
      url: `https://api-dev.manninggroup.com/ForemanNote/DailyReport`,
      method: 'POST',
      body: { ...requestObj, noteText: DPRDescription },
    }).then((response) => {
      if (response) {
        setDPRDescription(response.noteText);
      }
    });

    //Adding files to the formdata
    if (imageDataUrls.length > 0) {
      for (const i in newFiles) {
        formData.append('files', newFiles[i]);
      }
      formData.append('createdBy', 'Travis Arnell');
      formData.append('createdById', bbUserId.toString());
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...protectedResources.apiForeman,
          account: accounts[0],
        });
        await axios({
          url: `https://api-dev.manninggroup.com/ProjectBinder/${workorderId}/${schedulerId}/documenttype/5`,
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + response.accessToken,
          },
          data: formData,
        });
      })();
    }
  };

  const saveAndSubmitDPR = () => {
    setAlertType('success');
    setAlertText('Report saved and submitted successfully');
    saveReport();
    setTimeout(function () {
      setLoaderOpen(false);
      setAlert(false);
    }, 2000);
    setAlert(true);
    setOpenModal(true);
  };

  const captureSignature = async () => {
    setAlertType('success');
    setAlertText("Completed today's schedule work");
    setAlert(true);
    if (checkAll && checkedEquipments && calledManagerChecked) {
      stopDPRTimer();
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });
      const data = await callApiWithToken(response.accessToken, {
        url: 'https://api-dev.manninggroup.com/Foreman/Logout',
        method: 'POST',
        body: {
          foremanId: Number(bbUserId),
          schedulerId,
          isComplete: 1,
          ...(signatureRef == '' || signatureRef == null
            ? {}
            : { signatureData: signatureRef.toDataURL() }),
        },
      });
      setTimeout(function () {
        navigate('/');
      }, 1000);
    } else {
      setAlertType('alert');
      setAlertText('Please complete all the checklist items');
      setTimeout(function () {
        setAlert(false);
      }, 2000);
      setAlert(true);
    }
  };

  const finalChecklistBody = () => {
    return (
      <Box sx={style}>
        <Grid container sx={{ marginBottom: '30px' }}>
          <Grid item xs={6} align="left" className="signature-text">
            Final Checklist
          </Grid>
          <Grid item xs={6} align="right">
            <ModalCloseIcon
              onClick={() => setOpenModal(false)}
              sx={{ color: '#1E417C' }}
            />
          </Grid>

          <Grid></Grid>
          <Grid>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="success"
                      edge="start"
                      checked={checkAll}
                      onChange={() => setCheckAll(!checkAll)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 1 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Checked all these points"
                  />
                </ListItemButton>
              </ListItem>
              <ListSubheader>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <CircleIcon sx={{ width: '10px' }} />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Punch list and Punch print"
                  />
                </ListItemButton>
              </ListSubheader>
              <ListSubheader>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <CircleIcon sx={{ width: '10px' }} />
                  </ListItemIcon>
                  <ListItemText className="checbox-text" primary="Damages" />
                </ListItemButton>
              </ListSubheader>
              <ListSubheader>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <CircleIcon sx={{ width: '10px' }} />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Return Products"
                  />
                </ListItemButton>
              </ListSubheader>
              <ListSubheader>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <CircleIcon sx={{ width: '10px' }} />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Change order if any"
                  />
                </ListItemButton>
              </ListSubheader>
            </List>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="success"
                      edge="start"
                      checked={checkedEquipments}
                      onChange={() => setCheckedEquipments(!checkedEquipments)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 1 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Check to see if all equipment is accounted for and secured"
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => console.log('clicked')}
                  dense={true}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="success"
                      edge="start"
                      checked={calledManagerChecked}
                      onChange={() =>
                        setcalledManagerChecked(!calledManagerChecked)
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 1 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="checbox-text"
                    primary="Call Project Manager with update"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid sx={{ width: '-webkit-fill-available' }}>
            <p className="signature-labels">
              Customer Signature
              <GreenSwitch
                sx={{ color: '#43A047' }}
                checked={showSignaturePad}
                onChange={() => setShowSignaturePad(!showSignaturePad)}
              />
            </p>
            {showSignaturePad && (
              <Box className="signature-box">
                <SignaturePad ref={(ref) => setSignatureRef(ref)} />
              </Box>
            )}
          </Grid>
          <Grid sx={{ width: '-webkit-fill-available' }}>
            <Button
              variant="contained"
              className="modalSave"
              onClick={() => captureSignature()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <ActionAlerts
        open={alert}
        type={alertType}
        description={alertText}
        handleClose={() => setAlert(false)}
      />
      <Grid item xs={10}>
        <Loader open={loaderOpen} />
        <Grid container>
          <Grid item xs={5}>
            <div className="dpr-headings">Project Photos</div>
          </Grid>
          <Grid item xs={5}>
            <div className="current-time">
              Current Time : <span>{moment(currentTime).format('HH:mm')}</span>
            </div>
          </Grid>
        </Grid>
        <Grid container className="photos-container">
          {imageDataUrls.length > 0 &&
            imageDataUrls.map((result, index) => (
              <Grid item key={index} sx={{ margin: '0px 20px 20px 0px' }}>
                <div className="dpr-images-div">
                  <img
                    src={result}
                    width="130"
                    height="130"
                    sx={{ borderRadius: '4px' }}
                  />
                  <img
                    src={CloseIcon}
                    className="close-img-icon"
                    alt="close-icon"
                    onClick={() => removeImage(index, result)}
                  />
                </div>
              </Grid>
            ))}
          <Grid item sx={{ margin: '0px 20px 20px 0px' }}>
            {/* <Button onClick={handleClick}>Upload a file</Button> */}
            <input
              hidden
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              onChange={(e) => handleCapture(e.target)}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                {/* <PhotoCameraIcon
                fontSize="large"
                color="primary"
                sx={{ width: '180px', height: '180px' }}
              /> */}
                <Paper className="cameraButton">
                  <img
                    src={CameraIcon}
                    className="camera-icon"
                    alt="camera-icon"
                  />
                </Paper>
              </IconButton>
            </label>
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: '10px' }}>
          <Grid item xs={10}>
            <div className="dpr-headings">Returned Products</div>
          </Grid>
        </Grid>
        <Grid container className="returnedproducts-div">
          <TextField
            id="returned-product"
            value={ReturnedProduct}
            fullWidth
            multiline
            onChange={(e) => setReturnedProduct(e.target.value)}
            InputLabelProps={{ shrink: false }}
            label={false}
            variant="outlined"
          />
        </Grid>
        <Grid container sx={{ marginBottom: '10px' }}>
          <Grid item xs={10}>
            <div className="dpr-headings">Damaged Products</div>
          </Grid>
        </Grid>
        <Grid container className="returnedproducts-div">
          <TextField
            id="damaged-product"
            fullWidth
            onChange={(e) => setDamagedProduct(e.target.value)}
            value={DamagedProduct}
            multiline
            InputLabelProps={{ shrink: false }}
            label={false}
            variant="outlined"
          />
        </Grid>
        <Grid container sx={{ marginBottom: '10px' }}>
          <Grid item xs={10}>
            <div className="dpr-headings">Daily Progress Report</div>
          </Grid>
        </Grid>
        <Grid container className="returnedproducts-div">
          <TextField
            id="dpr-description"
            label="Description"
            onChange={(e) => setDPRDescription(e.target.value)}
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            minRows={3}
            value={DPRDescription}
          />
        </Grid>
        <Grid align="center">
          <Button variant="contained" className="save-dpr" onClick={saveDPR}>
            Save
          </Button>
          <Button
            variant="contained"
            className="submit-dpr"
            onClick={saveAndSubmitDPR}
          >
            Save & Submit
          </Button>
        </Grid>
      </Grid>
      {openModal && (
        <Modal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          body={finalChecklistBody()}
        />
      )}
    </>
  );
}

export default index;
