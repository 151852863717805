import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BriefIcon from '../../../images/sidemenu/Briefs.jsx';
import TimeClockIcon from '../../../images/sidemenu/TimeClock';
import ProjectBinderIcon from '../../../images/sidemenu/ProjectBinder';
import CrewRosterIcon from '../../../images/sidemenu/CrewRoster';
import CommunicationIcon from '../../../images/sidemenu/Communication';
import { useNavigate, useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Sidebar(props) {
  const navigate = useNavigate();
  const path = useLocation();
  const mode = localStorage.getItem('mode');
  const isCompleted = localStorage.getItem('isCompleted');
  const [value, setValue] = React.useState(
    mode == 'start' && !isCompleted ? 'time-clock' : 'project-binder'
  );

  const handleChange = (event, newValue) => {
    if (
      ['project-binder', '', 'time-clock', 'briefs', 'msds'].includes(newValue)
    ) {
      navigate(`/${newValue}`);
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    const pathName = path.pathname.split('/')[1];
    setValue(pathName);
  }, [value]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: '100vh',
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', paddingTop: '64px' }}
      >
        {mode != 'plan' && !isCompleted && (
          <Tab
            label={
              <div>
                <TimeClockIcon />
                <div className="sidemen-time">TIME CLOCK</div>
              </div>
            }
            value="time-clock"
            {...a11yProps('time-clock')}
          />
        )}
        <Tab
          label={
            <div>
              <ProjectBinderIcon />
              <div className="sidemen-time">PROJECT BINDER</div>
            </div>
          }
          value="project-binder"
          {...a11yProps('project-binder')}
        />
        <Tab
          label={
            <div>
              <BriefIcon />
              <div className="sidemen-time">BRIEFS</div>
            </div>
          }
          value="briefs"
          {...a11yProps('briefs')}
        />
        <Tab
          label={
            <div>
              <ProjectBinderIcon />
              <div className="sidemen-time">MSDS</div>
            </div>
          }
          value="msds"
          {...a11yProps('msds')}
        />
      </Tabs>
    </Box>
  );
}
