import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="16"
      fill="none"
      viewBox="0 0 21 16"
      style={{ marginTop: '3px' }}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M2.57 0h15.85c1.41 0 2.57 1.15 2.57 2.56v10.83a2.57 2.57 0 01-2.57 2.57H2.57A2.57 2.57 0 010 13.39V2.56C0 1.15 1.16 0 2.57 0zm15.85 14.79c.77 0 1.39-.62 1.39-1.39V2.57c0-.77-.62-1.39-1.39-1.39H2.57c-.77 0-1.39.62-1.39 1.39V13.4c0 .77.62 1.39 1.39 1.39h15.85zm-.06-11.54l-5.13 4.6 5.14 4.91c.23.22.24.6.02.83-.12.12-.27.18-.43.18-.14 0-.29-.05-.4-.16l-5.22-4.96-1.46 1.31a.579.579 0 01-.78 0L8.68 8.69 3.43 13.6c-.11.11-.26.16-.4.16-.16 0-.31-.07-.43-.19-.22-.24-.2-.61.03-.83L7.8 7.9 2.61 3.26a.578.578 0 01-.05-.83c.22-.25.59-.27.83-.05l5.62 5.03c.04.02.07.05.1.08 0 0 .01 0 .01.01l1.38 1.23 7.07-6.35c.24-.22.61-.2.83.04.22.24.2.61-.04.83z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
