import { ACTION_TYPES as types } from '../constants/action-types';

export function saveWorkOrder(workOrder) {
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_WORK_ORDER_SUCCESS,
      payload: workOrder,
    });
  };
}
