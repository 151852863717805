import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <rect width="31" height="31" fill="#FDD835" rx="4"></rect>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M25.657 21.145L17.072 6.913A1.892 1.892 0 0015.464 6c-.65 0-1.274.352-1.608.913L5.27 21.145a1.876 1.876 0 00-.027 1.897c.334.587.958.958 1.636.958h17.168a1.87 1.87 0 001.636-.958 1.877 1.877 0 00-.027-1.897zm-1.157 1.22a.513.513 0 01-.452.262H6.88a.518.518 0 01-.443-.786L15.02 7.607a.52.52 0 01.443-.253c.18 0 .352.1.443.253L24.49 21.84c.1.163.1.362.01.524zm-9.027-10.763c-.515 0-.922.28-.922.768 0 .75.046 1.668.09 2.585.046.917.091 1.834.091 2.584 0 .389.343.551.75.551.298 0 .732-.162.732-.55 0-.756.045-1.675.09-2.593.046-.914.09-1.828.09-2.576-.008-.488-.424-.769-.921-.769zm-.967 8.35c0-.533.424-.976.976-.976.524 0 .966.443.957.976 0 .524-.442.976-.957.976a.977.977 0 01-.976-.976z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
