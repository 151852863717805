import React from 'react';

export default function WorkOrdersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      fill="none"
      viewBox="0 0 18 23"
    >
      <path
        fill="#1E417C"
        stroke="#1E417C"
        strokeWidth="0.1"
        d="M7.882 14.883l-1.858-1.858a.522.522 0 00-.364-.153.514.514 0 00-.364.88l2.222 2.223a.531.531 0 00.728 0l4.47-4.47a.514.514 0 000-.728h0-.001a.53.53 0 00-.727 0l-4.106 4.106z"
      ></path>
      <path
        fill="#1E417C"
        stroke="#1E417C"
        strokeWidth="0.1"
        d="M17.053 4.465h.008v-.05c0-.987-.8-1.788-1.787-1.788h-2.405v-1.16a.515.515 0 00-.517-.517H5.66a.515.515 0 00-.517.517v1.16H2.738c-.988 0-1.788.8-1.788 1.788v15.847c0 .988.8 1.788 1.788 1.788h12.527c.987 0 1.788-.8 1.788-1.788V4.465zm-1.017 15.747h-.008v.05c0 .422-.34.763-.763.763H2.738a.762.762 0 01-.763-.763V4.415c0-.422.34-.763.763-.763h2.405v1.16c0 .288.23.518.517.518h6.692c.287 0 .516-.23.516-.517v-1.16h2.406c.422 0 .763.34.763.762v15.797zM6.177 1.975h5.659v2.32H6.177v-2.32z"
      ></path>
    </svg>
  );
}
