import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      fill="none"
      viewBox="0 0 19 23"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.232 13.726l1.26 2.36c.24.45.23.99-.04 1.42-.26.45-.73.71-1.24.71H1.452c-.51 0-.98-.27-1.24-.71-.27-.44-.28-.98-.04-1.43l1.26-2.36v-5.82c0-3.51 2.3-6.49 5.47-7.52.64-.2 1.89-.49 3.37-.33.02.01.05.01.07.01.38.04.77.12 1.18.24v.01c3.29.96 5.71 4 5.71 7.6v5.82zm-7.89-12.57c-3.73 0-6.75 3.03-6.75 6.75v5.34h13.49v-5.34c0-3.73-3.02-6.75-6.74-6.75zm7.87 15.9c.15 0 .23-.1.25-.14l.002-.004c.021-.043.076-.15.008-.286l-1.18-2.22H2.382l-1.19 2.22c-.07.14-.02.25.01.29l.002.004c.032.042.102.136.248.136h15.76zm-7.87 4.094c1.27 0 2.32-.93 2.52-2.15h1.22c-.22 1.88-1.8 3.35-3.73 3.35-1.94 0-3.52-1.47-3.73-3.35h1.2c.2 1.22 1.25 2.15 2.52 2.15z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
