import { callApiWithToken } from '../fetch';
import moment from 'moment';

let timer = null;
// 1800000 ms = 30 mins
export function startDPRTimer(accessToken, data) {
  try {
    clearInterval(timer);
    remind(accessToken, data);
    timer = setInterval(() => remind(accessToken, data), 300000);
  } catch (error) {
    throw error;
  }
}

const remind = (accessToken, data) => {
  const jsonData = JSON.stringify({
    schedulerId: data.schedulerId,
    time: new Date(),
  });
  localStorage.setItem('dprReminder', jsonData);
  callApiWithToken(accessToken, {
    url: `https://api-dev.manninggroup.com/Foreman/${data.blackberryuserId}`,
    method: 'GET',
  }).then((resp) => {
    console.log(resp);
    data.schedulerId = Number(data.schedulerId);
    resp.schedules.map((schedule) => {
      if (
        schedule.id == data.schedulerId
        // schedule.scheduleStatus != 'Completed'     for testing
      ) {
        callApiWithToken(accessToken, {
          url: `https://api-dev.manninggroup.com/ReminderNotification`,
          method: 'POST',
          body: data,
        });
      }
    });
  });
};

export function stopDPRTimer() {
  localStorage.removeItem('dprReminder');
  clearInterval(timer);
  return;
}

const load = () => {
  let isDprReminderRunning = localStorage.getItem('dprReminder');
  if (isDprReminderRunning) {
    localStorage.setItem('refreshed', true);
  } else {
    localStorage.setItem('reOpened', true);
  }
};
window.onload = load;
