import { ACTION_TYPES } from '../constants/action-types';

export const timeCardSave = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_TIMECARD_ENTRIES_SUCCESS:
      return [...state, ...action.payload];
    case ACTION_TYPES.DELETE_TIMECARD_ENTRIES_SUCCESS:
      return [];
    default:
      return state;
  }
};
