import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M22.605 16.816c-1.288-1.24-2.36-1.962-3.383-2.25-1.372-.398-2.648-.037-3.791 1.059l-.012.024-.036.024-1.324 1.324-.096-.06c-.783-.434-2.335-1.42-4.02-3.106l-.121-.12c-1.673-1.673-2.66-3.238-3.105-4.02l-.049-.097L8.041 8.21c1.095-1.143 1.444-2.407 1.047-3.78-.29-1.022-1.011-2.094-2.251-3.381A3.42 3.42 0 004.502 0a3.454 3.454 0 00-2.407.867l-.06.06C.685 2.276-.013 4.154 0 6.381c.024 3.815 2.119 8.172 5.61 11.663a22.362 22.362 0 002.226 1.938c.337.253.855.193 1.107-.145A.797.797 0 008.8 18.73a20.996 20.996 0 01-2.07-1.805C3.515 13.71 1.6 9.775 1.589 6.38c-.012-1.782.53-3.274 1.553-4.31l.036-.023A1.78 1.78 0 014.38 1.59c.506 0 .963.205 1.312.566C8.185 4.744 7.968 6.02 6.873 7.15L5.14 8.896a.77.77 0 00-.157.879c.012.024 1.072 2.395 3.732 5.055l.12.12c2.612 2.613 4.959 3.684 5.055 3.732.29.132.65.06.879-.156l1.745-1.734c.566-.541 1.072-.794 1.65-.794.89 0 1.961.626 3.345 1.962.71.686.759 1.805.097 2.54-1.047 1.035-2.516 1.564-4.285 1.564h-.048c-1.397 0-3.01-.385-4.67-1.107a.692.692 0 00-.313-.06.788.788 0 00-.723.481.783.783 0 00.41 1.035c1.877.807 3.659 1.228 5.284 1.228h.048c2.202 0 4.068-.698 5.404-2.034l.036-.036a3.41 3.41 0 00.89-2.432 3.35 3.35 0 00-1.034-2.323zM12.434.989h-.048a.801.801 0 00-.794.794c0 .433.36.782.794.782h.048c2.311 0 4.49.903 6.115 2.528a8.567 8.567 0 012.528 6.163c0 .216.084.409.228.553a.794.794 0 00.554.23v.143-.144c.433 0 .794-.349.794-.782.012-2.757-1.047-5.357-2.997-7.294C17.743 2.048 15.18.989 12.434.989z"
      ></path>
      <path
        fill="#000"
        d="M14.24 6.79c.397.108.987.277 1.673.95.662.663.83 1.253.939 1.638l.012.036a.787.787 0 00.987.53.75.75 0 00.469-.386.8.8 0 00.06-.601l-.012-.037c-.144-.481-.397-1.372-1.348-2.31-.975-.976-1.914-1.228-2.36-1.36a.681.681 0 00-.216-.037.801.801 0 00-.77.578.804.804 0 00.072.602c.12.216.29.349.494.397z"
      ></path>
    </svg>
  );
}

export default Icon;
