import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import '../../App.css';
import { useMsal } from '@azure/msal-react';
import Layout from '../../components/partials/layout/Layout';
import { loadUser } from '../../redux/actions/userActions';
import { saveWorkOrder } from '../../redux/actions/workOrderActions';
import { connect } from 'react-redux';
import { getUser } from '../../redux/selectors/homePage';
import { protectedResources } from '../../authConfig';
import CheckList from './safety-checklist/CheckList';
import SafetyPdf from '../briefs/safety-pdfs/SafetyPdf';

export function SafetyBriefs(props) {
  const selectedTab = localStorage.getItem('selectedTab');
  const [selectedButton, setSelectedButton] = useState(
    selectedTab && ['safety-pdfs', 'safety-checklist'].includes(selectedTab)
      ? selectedTab
      : 'safety-pdfs'
  );
  const { loadUser } = props;
  const { instance, accounts } = useMsal();
  const mode = localStorage.getItem('mode');

  useEffect(() => {
    localStorage.setItem('selectedTab', selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    (async () => {
      const response = await instance.acquireTokenSilent({
        ...protectedResources.apiForeman,
        account: accounts[0],
      });
      await loadUser(response.accessToken, accounts[0].username);
    })();
  }, []);

  return (
    <Layout heading="Briefs">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <Button
            onClick={() => setSelectedButton('safety-pdfs')}
            variant="outlined"
            className={
              selectedButton === 'safety-pdfs'
                ? 'orange-bg mt10'
                : 'unselected-button mt10'
            }
          >
            SAFETY PDF
          </Button>
          <Button
            onClick={() => setSelectedButton('safety-checklist')}
            variant="outlined"
            className={
              selectedButton === 'safety-checklist'
                ? 'orange-bg'
                : 'unselected-button'
            }
          >
            SAFETY BRIEF CHECKLIST
          </Button>
        </Grid>
        {selectedButton === 'safety-pdfs' && <SafetyPdf />}
        {selectedButton === 'safety-checklist' && <CheckList />}
      </Grid>
    </Layout>
  );
}

function mapStateToProps(store) {
  const user = getUser(store);
  return {
    user,
    workOrders: user.workOrders ? user.workOrders : [],
  };
}

const mapDispatchToProps = {
  loadUser,
  saveWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetyBriefs);
